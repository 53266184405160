import classNames from 'classnames';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import { useProductList } from 'components/commercetools-ui/organisms/product/product-list/context';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './filter-item.module.scss';
import { FilterFieldValue, FilterFieldRangeValue } from '../../../../../../../../../../../types/product/FilterField';

interface FilterItemOptionProps {
  facetOption: FilterFieldValue | FilterFieldRangeValue;
  facetAttribute: string;
}

const FilterItemOption = ({ facetOption, facetAttribute }: FilterItemOptionProps) => {
  const { formatMessage } = useFormat({ name: 'narrow' });
  const { refine } = useProductList();

  const isDisabled = !facetOption.count;

  const handleOnChange = (field: string, value: string) => {
    !isDisabled && refine(field, value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleOnChange(facetAttribute, facetOption.value);
    }
  };

  return (
    <li>
      <label
        htmlFor={`${facetAttribute}.${facetOption.value}`}
        className={classNames(styles['filter-item-option'], { disabled: isDisabled })}
      >
        <Checkbox
          id={`${facetAttribute}.${facetOption.value}`}
          name={facetOption.value}
          label={formatMessage({ id: facetOption.key, defaultMessage: facetOption.name.replace('Product Type', '') })}
          className={styles['filter-item-option-checkbox']}
          onChange={() => handleOnChange(facetAttribute, facetOption.value)}
          onKeyDown={handleKeyDown}
          checked={facetOption.selected}
          disabled={isDisabled}
          refreshOnStateChange
        />
        <span>({facetOption.count})</span>
      </label>
    </li>
  );
};

export default FilterItemOption;
