import { useState } from 'react';
import classNames from 'classnames';
import styles from './ArchivedSection.module.scss';
import ArchivedProduct from './Product/Product';
import { useFormat } from '../../../../../../../helpers/hooks/useFormat';
import { Category } from '../../../../../../../types/category';
import { TNikonProduct } from '../../../../../../../types/TNikonProduct';
import Pagination from '../../../../../../nikon-ui/atoms/pagination';
import { ArchivedSearch } from '../../../../../atoms/archived-search';
import Collapse, { useCollapseContext } from '../../../../../atoms/collapse/Collapse';
import { useProductList } from '../../context';

interface Props {
  category?: Category;
  products: TNikonProduct[];
}

const ArchivedSection = ({ products, category }: Props) => {
  const { archiveTotalItems, archiveLimit, archiveOffset } = useProductList();
  const { open, toggleOpenClose } = useCollapseContext();
  const { formatMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatMessagePLP } = useFormat({ name: 'plp' });
  const [loading, setLoading] = useState(false);

  const resultsText =
    archiveTotalItems === 1
      ? formatMessage({ id: 'plp.filters.result', defaultMessage: '1 Result' })
      : formatMessage({
          id: 'plp.filters.results',
          defaultMessage: `${archiveTotalItems} Results`,
          values: { count: archiveTotalItems },
        });

  return (
    <section id="archived-section" className={styles['container']}>
      <div>
        <div
          className={classNames(styles['collapse-button'], { [styles['down']]: !open }, { [styles['up']]: open })}
          onClick={toggleOpenClose}
        >
          <div>
            {formatMessagePLP({
              id: 'archived.title',
              defaultMessage: 'Archived {categoryName}',
              values: { categoryName: category?.name || '' },
            })}
          </div>
        </div>
        <Collapse open={open}>
          <div className={styles['collapse-content-container']}>
            <div>
              <div>{resultsText}</div>
              <div className={styles['search-pagination-section']}>
                <div>
                  {!loading && (
                    <ArchivedSearch
                      queryKey="archiveQuery"
                      placeholder={formatMessagePLP({ id: 'searchProduct', defaultMessage: 'Search Product name' })}
                      archivedProducts={products}
                    />
                  )}
                </div>
                <Pagination
                  pagination={{ total: archiveTotalItems, limit: archiveLimit, offset: archiveOffset }}
                  offsetKey="archiveOffset"
                  limitKey="archiveLimit"
                  scroll={false}
                  onLoading={setLoading}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-60 md:grid-cols-3 lg:grid-cols-6 lg:gap-50">
              {products.map((product, key) => (
                <ArchivedProduct key={`${product.productId}_${key}`} product={product as TNikonProduct} />
              ))}
            </div>
          </div>
        </Collapse>
      </div>
    </section>
  );
};

export default ArchivedSection;
