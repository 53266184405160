import { useState } from 'react';
import classNames from 'classnames';
import Button from 'components/commercetools-ui/atoms/button';
import { useProductList } from 'components/commercetools-ui/organisms/product/product-list/context';
import { useFormat } from 'helpers/hooks/useFormat';
import { FilterField } from '../../../../../../../../../../../types/product/FilterField';
import FilterItem from '../filter-item/filter-item';
import styles from '../NarrowBy.module.scss';

const INITIAL_FILTERS_QUANTITY = 4;

const NarrowByDesktop = () => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { facetsConfiguration, totalItems } = useProductList();
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(false);

  const isExpandable = facetsConfiguration?.length > INITIAL_FILTERS_QUANTITY;

  const handleOnToggleExpand = () => {
    setIsFiltersExpanded((prevValue) => !prevValue);
  };

  const resultsText =
    totalItems === 1
      ? formatMessage({ id: 'plp.filters.result', defaultMessage: '1 Result' })
      : formatMessage({
          id: 'plp.filters.results',
          defaultMessage: `${totalItems} Results`,
          values: { count: totalItems },
        });

  const buttonText = isFiltersExpanded
    ? formatMessage({ id: 'plp.filters.showFewer', defaultMessage: 'Show Fewer Filters' })
    : formatMessage({ id: 'plp.filters.showMore', defaultMessage: 'Show More Filters' });

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div>{formatMessage({ id: 'plp.filters.narrowBy', defaultMessage: 'Narrow By' })}</div>
        <div className={styles['results']}>{resultsText}</div>
      </div>
      <div className={styles['content']}>
        <ul className={classNames(styles['content-list'], { compress: !isFiltersExpanded })}>
          {facetsConfiguration?.map((filter: FilterField) => {
            if (!filter.values?.length) return null;

            return <FilterItem key={filter.field} facet={filter} />;
          })}
        </ul>
        {isExpandable && (
          <Button onClick={handleOnToggleExpand} variant="secondary" size="xs" className={styles['content-button']}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default NarrowByDesktop;
