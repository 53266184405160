import { useState } from 'react';
import { Modal, ModalHeader } from 'react-bootstrap';
import Stack from 'react-bootstrap/Stack';
import { useFormat } from 'helpers/hooks/useFormat';
import FacetItemOption from './filter-item-option';
import styles from './filter-item.module.scss';
import { FilterField } from '../../../../../../../../../../../types/product/FilterField';

interface FilterItemProps {
  facet: FilterField;
}

const FilterItem = ({ facet }: FilterItemProps) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  const handleShowModal = () => {
    setShowHelpModal(true);
  };
  const handleCloseModal = () => setShowHelpModal(false);

  const facetTitle =
    facet.label === 'productType'
      ? formatProductMessage({ id: 'plp.filters.productTypeTitle', defaultMessage: 'Product Type' })
      : facet.label;

  return (
    <>
      <li key={facet.field} className={styles['filter-item']}>
        <Stack direction="horizontal">
          <p className={styles['filter-item-title']}>{facetTitle}</p>
          {facet.tooltip ? (
            <div className="ms-auto">
              <span className={styles['help-link']} onClick={handleShowModal}>
                {formatProductMessage({ id: 'plp.filters.helpTooltip', defaultMessage: 'Help?' })}
              </span>
            </div>
          ) : null}
        </Stack>
        <ul className={styles['filter-item-list']}>
          {facet.values?.map((facetOption) => (
            <FacetItemOption
              key={`${facet.field}.${facetOption.value}`}
              facetOption={facetOption}
              facetAttribute={facet.field}
            />
          ))}
        </ul>
      </li>

      {/* Help Modal component */}
      <Modal className={styles['help-modal']} size="lg" show={showHelpModal} onHide={handleCloseModal}>
        <ModalHeader closeButton></ModalHeader>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: facet.tooltip }}></div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterItem;
