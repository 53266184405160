import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import cloneDeep from 'lodash/cloneDeep';
import { useFormat } from 'helpers/hooks/useFormat';
import { refinementRemovedEventName, refinementsClearedEventName } from './constants';
import { ActiveRefinement, ProductListContextShape, RefinementRemovedEvent, Sort, UiState, View } from './types';
import { FilterField, FilterFieldValue } from '../../../../../../../types/product/FilterField';
import { useLocale } from '../../../../../../helpers/hooks/useLocale';
import { E_FIELDS } from '../../product-table/Header/interfaces';
import useTableFields from '../../product-table/hooks/useTableFields';
import { E_SORT_OPTIONS_KEY, MAP_SORT_OPTIONS, MAP_TABLE_COLUMNS_SORT, SortByDropdownItems } from '../constants';
import { PriceConfiguration } from '../types';
import { replacePlaceholdersInString } from '../utils';

export const ProductListContext = createContext<ProductListContextShape>({
  pricesConfiguration: {},
  facetsConfiguration: [],
  totalItems: 0,
  archiveTotalItems: 0,
  tableViewColumns: [],
  tableDefinitions: [],
  activeSortOptions: [],
  defaultSortOption: '',
  activeRefinements: [],
  activeLimit: 12,
  updateUiState() {},
  updatePricesConfiguration() {},
  updateFacetsConfiguration() {},
  refine() {},
  replaceSort() {},
  removeAllRefinements() {},
  loadMore() {},
  view: 'grid',
  useListView() {},
  useGridView() {},
  useTableView() {},
  offset: 0,
  archiveLimit: 12,
  isLensesSection: false,
  archiveOffset: 0,
  setLimit() {},
  sortBy: '',
  tableSortBy: '',
  sortDropdownItems: [{ value: '', label: '' }],
  sortOption: '',
  getSortByKey() {
    return undefined;
  },
  tableFields: {
    direction: 'up',
    fieldsToRender: [],
    isAnyFieldHidden: false,
    setDirection() {},
    toggleDirection() {
      return 'up';
    },
    totalFields: 0,
  },
  fieldSelected: E_FIELDS.FOCAL_LENGTH,
  setFieldSelected() {},
  getTableViewSortByKey() {
    return { value: 'asc', attribute: '' };
  },
  goToTopResults() {},
  goToArchiveSection() {},
  appendQueryParams() {},
  removeQueryParams() {},
});

const ProductListProvider: React.FC = ({ children }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'product' });

  const [activeRefinements, setActiveRefinements] = useState<ActiveRefinement[]>([]);
  const [uiState, setUiState] = useState<UiState>({
    totalItems: 0,
    archiveTotalItems: 0,
    tableViewColumns: [],
    tableDefinitions: [],
    activeSortOptions: [],
    defaultSortOption: '',
  });
  const [view, setView] = useState<View>('grid');

  const applyRefinements = useCallback(
    (
      facetsConfiguration?: FilterField[],
      sort?: Sort | Sort[],
      limit?: number,
      queryParams?: Record<string, string>,
      scrollToTheTop = true,
      removeQueryParams?: string[],
    ) => {
      const params = new URLSearchParams();

      Object.keys(router.query).forEach((key) => {
        const value = router.query[key];
        // * We need this because we want to reset all sortAttributes query params
        const isSortAttribute = key.startsWith('sortAttributes');
        if (value) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              if (!isSortAttribute) {
                params.set(key, item);
              }
            });
          } else {
            if (!isSortAttribute) {
              params.set(key, value);
            }
          }
        }
      });

      if (facetsConfiguration) {
        Object.values(facetsConfiguration).forEach((configuration) => {
          configuration.values?.forEach((option, index) => {
            const key = `facets[${configuration.field}][terms][${index}]`;
            if (option.selected) {
              params.set(key, option.value);
            } else {
              params.delete(key);
            }
          });
        });
      }

      if (sort) {
        if (Array.isArray(sort)) {
          sort.map((item, idx) => {
            params.set(`sortAttributes[${idx}][${item.attribute}]`, item.value);
          });
        } else if (sort.attribute !== E_SORT_OPTIONS_KEY.FEATURED) {
          params.set(`sortAttributes[0][${sort.attribute}]`, sort.value);
        }
      }

      if (queryParams) {
        Object.keys(queryParams).forEach((key) => {
          const value = queryParams[key];
          params.set(key, value);
        });
      }

      if (limit) params.set('limit', limit.toString());

      if (removeQueryParams) {
        removeQueryParams.forEach((key) => params.delete(key));
      }

      router.replace({ pathname: router.asPath.split('?')[0].split('#')[0], query: params.toString() }, undefined, {
        scroll: scrollToTheTop,
      });
    },
    [router],
  );

  const limitStep = useMemo(() => 12, []);
  const archiveLimitStep = useMemo(() => 12, []);

  const activeLimit = useMemo<number>(() => {
    return router.query.limit ? +router.query.limit : limitStep;
  }, [router.query, limitStep]);

  const archiveLimit = useMemo<number>(() => {
    return router.query.archiveLimit ? +router.query.archiveLimit : archiveLimitStep;
  }, [router.query, archiveLimitStep]);

  const sortBy = useMemo<string | undefined>(() => {
    return router.query.sortBy ? (router.query.sortBy as string) : undefined;
  }, [router.query]);

  const tableSortBy = useMemo<string | undefined>(() => {
    return router.query.tableSortBy ? (router.query.tableSortBy as string) : undefined;
  }, [router.query]);

  const [facetsConfiguration, setFacetsConfiguration] = useState<FilterField[]>([]);

  // SORT FOR GRID/LIST VIEW
  const sortDropdownItems = SortByDropdownItems.filter((item) => {
    return uiState.activeSortOptions?.some((key) => item.value === key);
  }).map((item) => ({
    label: formatMessage({ id: `plp.sort.${item.value}`, defaultMessage: item.label }),
    value: item.value,
  }));
  const defaultSort = uiState.defaultSortOption ?? sortDropdownItems[0]?.value;
  const sortOption = sortBy ?? defaultSort;

  // SORT FOR TABLE VIEW
  const tableFields = useTableFields();
  const [fieldSelected, setFieldSelected] = useState<E_FIELDS>(
    (tableSortBy as E_FIELDS) ||
      tableFields.fieldsToRender.find(({ key }) => key === E_FIELDS.FOCAL_LENGTH)?.key ||
      tableFields.fieldsToRender[0].key,
  );

  const goToTopResults = useCallback(() => document.getElementById('plp-grid-container')?.scrollIntoView(), []);
  const goToArchiveSection = useCallback(() => document.getElementById('archived-section')?.scrollIntoView(), []);

  const appendQueryParams = useCallback(
    (params: Record<string, string>) => {
      applyRefinements(undefined, undefined, undefined, params, false);
    },
    [applyRefinements],
  );

  const removeQueryParams = useCallback(
    (params: string[]) => {
      applyRefinements(undefined, undefined, undefined, undefined, false, params);
    },
    [applyRefinements],
  );

  const useListView = useCallback(() => {
    setView('list');
    const sort = getSortByKey(sortOption);
    applyRefinements(facetsConfiguration, sort, activeLimit, { sortBy: sortOption }, false);
  }, [applyRefinements, facetsConfiguration, sortOption, activeLimit]);

  const useGridView = useCallback(() => {
    setView('grid');
    const sort = getSortByKey(sortOption);
    applyRefinements(facetsConfiguration, sort, activeLimit, { sortBy: sortOption }, false);
  }, [applyRefinements, facetsConfiguration, sortOption, activeLimit]);

  const useTableView = useCallback(async () => {
    setView('table');
    const sort = getTableViewSortByKey(fieldSelected, tableFields.direction);
    applyRefinements(facetsConfiguration, sort, uiState.totalItems, { tableSortBy: fieldSelected }, false);
  }, [applyRefinements, facetsConfiguration, fieldSelected, uiState.totalItems, tableFields.direction]);

  useEffect(() => {
    switch (uiState.view) {
      case 'table':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useTableView();
        break;
      case 'list':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useListView();
        break;
      case 'grid':
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useGridView();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uiState.view]);

  const { locale } = useLocale();
  const getSortByKey = useCallback(
    (key: string): Sort | Sort[] | undefined => {
      if (key === E_SORT_OPTIONS_KEY.FEATURED) {
        return { attribute: key, value: 'desc' };
      }
      const sort = MAP_SORT_OPTIONS[key];
      if (sort) {
        if (Array.isArray(sort)) {
          const sortArr = sort.map((item): Sort => {
            const attribute = replacePlaceholdersInString(item.attribute, { locale: locale.replace(/_/g, '-') });
            return { attribute, value: item.value };
          });
          return sortArr;
        } else {
          const attribute = replacePlaceholdersInString(sort.attribute, { locale: locale.replace(/_/g, '-') });
          return { attribute, value: sort.value };
        }
      }
    },
    [locale],
  );

  const getTableViewSortByKey = useCallback((key: string, direction: 'down' | 'up'): Sort | Sort[] => {
    const sortWithDirections = MAP_TABLE_COLUMNS_SORT[key];
    const sort = sortWithDirections[direction];

    if (Array.isArray(sort)) {
      const sortArr = sort.map((item): Sort => {
        const attribute = replacePlaceholdersInString(item.attribute, { locale: locale.replace(/_/g, '-') });
        return { attribute, value: item.value };
      });
      return sortArr;
    } else {
      const attribute = replacePlaceholdersInString(sort.attribute, { locale: locale.replace(/_/g, '-') });
      return { attribute, value: sort.value };
    }
  }, []);

  const isLensesSection = useMemo(() => {
    const lensesURLs = ['/lenses', '/dslr-lenses', '/mirrorless-lenses'];
    return lensesURLs.some((url) => router.asPath.startsWith(url));
  }, [router.asPath]);

  const [pricesConfiguration, setPricesConfiguration] = useState<Record<string, PriceConfiguration>>({});

  const [offset, setOffset] = useState(0);
  const [archiveOffset, setArchiveOffset] = useState(0);

  const activeSort = useMemo<Sort | undefined>(() => {
    for (const q in router.query) {
      const match = q.match(/sortAttributes\[0\]\[(.+)\]/);

      if (match?.[1]) return { attribute: match[1], value: router.query[q] as 'asc' | 'desc' };
    }
  }, [router.query]);

  useEffect(() => {
    setOffset(Number(router.query.offset));
    setArchiveOffset(Number(router.query.archiveOffset));

    if (router.query.archiveQuery || router.query.archiveOffset || router.query.archiveLimit) {
      goToArchiveSection();
    }

    let queryParamSort: Sort | undefined = undefined;
    for (const q in router.query) {
      const match = q.match(/sortAttributes\[0\]\[(.+)\]/);

      if (match?.[1]) queryParamSort = { attribute: match[1], value: router.query[q] as 'asc' | 'desc' };
    }

    const tableSortByQuery = router.query.tableSortBy;
    if (tableSortByQuery && queryParamSort) {
      let directionFromQueryParam: 'up' | 'down' = 'up';
      Object.values(MAP_TABLE_COLUMNS_SORT).forEach((value) => {
        Object.keys(value).forEach((key) => {
          let obj = value[key as 'up' | 'down'] as Sort;
          if (Array.isArray(obj)) {
            obj = obj[0];
          }
          const attribute = replacePlaceholdersInString(obj.attribute, { locale: locale.replace(/_/g, '-') });
          if (attribute === queryParamSort?.attribute && obj.value === queryParamSort?.value) {
            directionFromQueryParam = key as 'up' | 'down';
          }
        });
      });
      tableFields.setDirection(directionFromQueryParam);
    }
  }, [router.query]);

  const updatePricesConfiguration = useCallback((newPricesConfiguration: Record<string, PriceConfiguration>) => {
    setPricesConfiguration(newPricesConfiguration);
  }, []);

  const updateFacetsConfiguration = useCallback((newFacetsConfiguration: FilterField[]) => {
    setFacetsConfiguration(newFacetsConfiguration);
  }, []);

  const updateUiState = useCallback((newUiState: UiState) => {
    setUiState(newUiState);
  }, []);

  useEffect(() => {
    const refinements = [] as ActiveRefinement[];

    const newFacetsConfiguration = cloneDeep(facetsConfiguration);

    const addRefinement = (facet: FilterField, option: FilterFieldValue) => {
      refinements.push({
        attribute: facet.field,
        label: option.name,
        key: option.key,
        refine: () => {
          refine(facet.field, option.value, false);

          window.dispatchEvent(
            new CustomEvent<RefinementRemovedEvent>(refinementRemovedEventName, {
              detail: { attribute: facet.field },
            }),
          );
        },
      });
    };

    newFacetsConfiguration
      .filter((facet) => facet?.values?.some(({ selected }) => selected))
      .forEach((facet) => {
        facet.values?.filter(({ selected }) => selected)?.forEach((option) => addRefinement(facet, option));
      });

    setActiveRefinements((prevState) => {
      return refinements.sort((a, b) => {
        const indexA = prevState.findIndex(({ attribute, label }) => attribute === a.attribute && label === a.label);
        const indexB = prevState.findIndex(({ attribute, label }) => attribute === b.attribute && label === b.label);

        if (indexA === -1 && indexB === -1) return 0;
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;

        return indexA - indexB;
      });
    });
  }, [facetsConfiguration, applyRefinements]);

  const replaceSort = useCallback(
    (newSort: Sort | Sort[], sortBy?: Record<string, string>, limit?: number) => {
      applyRefinements(facetsConfiguration, newSort, limit ?? activeLimit, sortBy, false);
    },
    [facetsConfiguration, applyRefinements, activeLimit],
  );

  const refine = useCallback(
    (attribute: string, value: string, selected?: boolean) => {
      const newFacetsConfiguration = cloneDeep(facetsConfiguration);
      if (!newFacetsConfiguration?.length) return;

      const facetIndex = newFacetsConfiguration.findIndex((f) => f.field === attribute);
      if (facetIndex === -1 || facetIndex === undefined) return;

      const facetValueIndex = newFacetsConfiguration?.[facetIndex]?.values?.findIndex((v) => v.value === value);
      if (facetValueIndex === -1 || facetValueIndex === undefined) return;

      const isValueSelected = newFacetsConfiguration[facetIndex].values?.[facetValueIndex]?.selected;

      // @ts-ignore
      newFacetsConfiguration[facetIndex].values[facetValueIndex].selected = selected ?? !isValueSelected;

      updateFacetsConfiguration(newFacetsConfiguration);
      applyRefinements(newFacetsConfiguration, activeSort, undefined, undefined, false);
    },
    [facetsConfiguration, applyRefinements, activeSort],
  );

  const loadMore = useCallback(() => {
    applyRefinements(facetsConfiguration, activeSort, activeLimit + limitStep, undefined, false);
  }, [facetsConfiguration, activeSort, applyRefinements, activeLimit, limitStep]);

  const removeAllRefinements = useCallback(() => {
    const newFacetsConfiguration = cloneDeep(facetsConfiguration);

    newFacetsConfiguration.forEach((facet) => {
      facet.values?.forEach((option) => {
        option.selected = false;
      });
    });

    updateFacetsConfiguration(newFacetsConfiguration);
    applyRefinements(newFacetsConfiguration, activeSort, undefined, undefined, false);

    window.dispatchEvent(new CustomEvent(refinementsClearedEventName));
  }, [applyRefinements, facetsConfiguration, activeSort]);

  const setLimit = useCallback(
    (limit: number) => {
      const params = router.query;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
      const { slug, ...rest } = params;

      const withLimitParams = {
        ...rest,
        limit: limit.toString(),
      };

      router.replace({ pathname: router.asPath.split('?')[0], query: withLimitParams }, undefined, { scroll: false });
    },
    [router],
  );

  const value = useMemo(
    () => ({
      ...uiState,
      facetsConfiguration,
      pricesConfiguration,
      activeSort,
      activeLimit,
      activeRefinements,
      updateUiState,
      updateFacetsConfiguration,
      updatePricesConfiguration,
      refine,
      replaceSort,
      removeAllRefinements,
      loadMore,
      view,
      useListView,
      useGridView,
      useTableView,
      offset,
      archiveOffset,
      archiveLimit,
      isLensesSection,
      sortBy,
      setLimit,
      tableSortBy,
      sortDropdownItems,
      sortOption,
      getSortByKey,
      tableFields,
      fieldSelected,
      setFieldSelected,
      getTableViewSortByKey,
      goToTopResults,
      goToArchiveSection,
      appendQueryParams,
      removeQueryParams,
    }),
    [
      uiState,
      pricesConfiguration,
      facetsConfiguration,
      activeSort,
      activeLimit,
      activeRefinements,
      updateUiState,
      updateFacetsConfiguration,
      updatePricesConfiguration,
      refine,
      replaceSort,
      removeAllRefinements,
      loadMore,
      view,
      useListView,
      useGridView,
      useTableView,
      offset,
      archiveOffset,
      archiveLimit,
      isLensesSection,
      sortBy,
      setLimit,
      tableSortBy,
      sortDropdownItems,
      sortOption,
      getSortByKey,
      tableFields,
      fieldSelected,
      setFieldSelected,
      getTableViewSortByKey,
      goToTopResults,
      goToArchiveSection,
      appendQueryParams,
      removeQueryParams,
    ],
  );

  return <ProductListContext.Provider value={value}>{children}</ProductListContext.Provider>;
};

export default ProductListProvider;

export const useProductList = () => useContext(ProductListContext);
