import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Form } from 'react-bootstrap';
import styles from './ArchivedSearch.module.scss';
import { CommonSearchProps } from './interfaces';
import SearchItem from './SearchItem';
import useTypeAheadArchived from '../../../../frontastic/hooks/useTypeAheadArchived';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { Locales } from '../../../../helpers/utils/swiftType';
import { useProductList } from '../../organisms/product/product-list/context';

export const ArchivedSearch = ({ queryKey, placeholder, archivedProducts }: CommonSearchProps) => {
  const router = useRouter();
  const { appendQueryParams, removeQueryParams } = useProductList();
  const { slug, ...query } = router.query;
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [isSubmitted, setSubmitted] = useState(false);
  const { searchTerm, searchResults, handleSearchTermChange, isLoading, fetchNextPage, totalResults } =
    useTypeAheadArchived(router?.locale?.replace('-', '_') as Locales, archivedProducts);
  const [cachedResults, setCachedResults] = useState(searchResults);
  const [results, setResults] = useState(searchResults);

  useEffect(() => {
    setResults(searchResults);
    setCachedResults(searchResults);
  }, [searchResults]);

  const isResultsListVisible = (isLoading && searchTerm) || results.length > 0;
  const [value, setValue] = useState((query[queryKey] as string) || '');
  const [paginationConfig, setPaginationConfig] = useState({ idx: -1, scrollIdx: -1, trigger: 2, limit: 8 });
  const [needsNextPage, setNeedsNextPage] = useState(false);

  useEffect(() => {
    const getPage = async () => {
      if (needsNextPage) {
        const data = await fetchNextPage();
        setCachedResults([...cachedResults, ...data]);
      }
    };
    getPage();
    setNeedsNextPage(false);
  }, [needsNextPage]);

  const submit = () => {
    const new_query = { [queryKey]: value };
    appendQueryParams(new_query);
    setSubmitted(true);
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    submit();
  };

  const handleOnKeyDown = async (e: React.KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (paginationConfig.scrollIdx >= -1 && paginationConfig.scrollIdx < cachedResults.length - 1) {
          setPaginationConfig((prev) => ({ ...prev, scrollIdx: prev.scrollIdx + 1 }));
          if (paginationConfig.scrollIdx === paginationConfig.trigger) {
            setPaginationConfig((prev) => ({ ...prev, trigger: prev.trigger + prev.limit }));
            setNeedsNextPage(true);
          }
        }
        if (paginationConfig.idx >= -1 && paginationConfig.idx < results.length - 1) {
          setPaginationConfig((prev) => ({ ...prev, idx: prev.idx + 1 }));
          setValue(results[paginationConfig.idx + 1].name);
        }
        if (paginationConfig.idx === results.length - 1) {
          // its the final of the results - we need to move the scroll
          const idx = paginationConfig.scrollIdx + 2;
          if (idx < cachedResults.length) {
            const newRes = [...results];
            newRes.shift();
            newRes.push(cachedResults[idx]);
            setValue(cachedResults[idx].name);
            setResults(newRes);
          }
        }
        break;

      case 'ArrowUp':
        e.preventDefault();
        if (paginationConfig.scrollIdx > 0) {
          setPaginationConfig((prev) => ({ ...prev, scrollIdx: prev.scrollIdx - 1 }));
        }
        if (paginationConfig.idx > 0) {
          setPaginationConfig((prev) => ({ ...prev, idx: prev.idx - 1 }));
          setValue(results[paginationConfig.idx - 1].name);
        }
        if (paginationConfig.idx === 0 && paginationConfig.scrollIdx > 0) {
          const idx = paginationConfig.scrollIdx - 1;
          if (idx < cachedResults.length) {
            const newRes = [...results];
            newRes.pop();
            newRes.unshift(cachedResults[idx]);
            setValue(cachedResults[idx].name);
            setResults(newRes);
          }
        }
        break;

      case 'Enter':
        submit();
        break;
      default:
        setPaginationConfig((prev) => ({ ...prev, idx: -1, scrollIdx: -1, trigger: 2 }));
    }
  };

  const clearSearch = () => {
    handleSearchTermChange('');
    setValue('');
    removeQueryParams([queryKey]);
  };

  const handleOnClick = () => {
    handleSearchTermChange(value);
    setSubmitted(false);
  };

  return (
    <Form className={`d-flex ${styles['search-field']}`} onSubmit={handleOnSubmit}>
      <Form.Control
        type="search"
        placeholder={placeholder}
        aria-label="Search"
        onChange={(e) => {
          setValue(e.target.value);
          handleSearchTermChange(e.target.value);
        }}
        onKeyDown={handleOnKeyDown}
        onClick={handleOnClick}
        value={value}
      />
      {value && (
        <button
          type="reset"
          className={`ss-delete ${styles['clear-search']}`}
          aria-label="Clear Search"
          onClick={clearSearch}
        />
      )}

      <button type="submit" className={`${styles['submit-search']} ${value ? styles.active : ''}`}>
        <img src={`/images/search.png`} alt={formatCommonMessage({ id: 'search.icon', defaultMessage: 'Search' })} />
      </button>

      {/* this is auto suggestion */}
      {isResultsListVisible && !isSubmitted && (
        <div className={styles['search-results-container']}>
          <header className={styles['search-results-title']}>
            {formatCommonMessage({
              id: 'search.resultsTitle.withNumber',
              defaultMessage: '{num} Results',
              values: { num: totalResults },
            })}
          </header>

          {isLoading && (
            <div className={styles['search-results-loader-container']}>
              <img
                src={`/images/icon_loading.gif`}
                alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
              />
            </div>
          )}

          <ol role="listbox" className={styles['search-results-list']}>
            {results.map((result, idx) => (
              <SearchItem
                // * key={idx} Needed for re-rendering on pagination scroll
                key={idx}
                name={result.name}
                isSelected={idx === paginationConfig.idx}
                onEvent={(name) => {
                  setValue(name);
                  setPaginationConfig((prev) => ({ ...prev, idx: -1 }));
                }}
                onClick={submit}
              />
            ))}
          </ol>
        </div>
      )}
    </Form>
  );
};
