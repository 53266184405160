import { useEffect, Fragment, useState } from 'react';
import { useRouter } from 'next/router';
import { Col, Container, Row } from 'react-bootstrap';
import ContentBlocks from 'components/contentstack/ContentBlocks';
import Pagination from 'components/nikon-ui/atoms/pagination';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalizationInfo } from 'project.config';
import { TLocalizationInfo } from 'types/TLocalizationInfo';
import styles from './SearchList.module.scss';
import SearchListLoading from './searchListLoading';
import { useSearch } from '../../context';
import FeaturedProductForSearch from '../FeaturedProductForSearch/FeaturedProductForSearch';
import LearnAndMoreForSearch from '../LearnAndMoreForSearch/LearnAndMoreForSearch';
import OtherForSearch from '../OtherForSearch/OtherForSearch';
import PressRoomForSearch from '../PressRoomForSearch/PressRoomForSearch';
import ProductTilesForSearch from '../ProductTilesForSearch/ProductTilesForSearch';
import { fullFilters, searchMenus } from '../SearchBrowBar/constant';

const SearchList = () => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const router = useRouter();
  const localizationInfo: TLocalizationInfo = getLocalizationInfo(router.locale);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const { searchResults, searchTerm, searchInfo, changePage, isLoading, page, featuredSearchItems, isFacetLoading } =
    useSearch();

  useEffect(() => {
    if (router.query?.limit) {
      if (!router.query?.offset) {
        return changePage(1);
      }
      return changePage(Number(router.query.offset) / Number(router.query.limit) + 1);
    }
  }, [router.query?.offset, router.query?.limit]);

  //@ts-ignore
  const isOverviewTab = router.query?.facet === fullFilters[searchMenus.RESULT_OVERVIEW] || !router.query?.facet;
  const featuredProduct = featuredSearchItems?.featuredProduct;
  const featuredCategoryContentBlocks = featuredSearchItems?.featuredCategory?.contentBlocks;
  const showFeaturedItem = isOverviewTab && page === 1 && (featuredProduct || featuredCategoryContentBlocks);
  const isSearchResultsLoading = isLoading || isPaginationLoading || isFacetLoading;
  const showResultsList = Boolean(searchResults.length) || showFeaturedItem || isSearchResultsLoading;

  return showResultsList ? (
    <Container className={styles[`nkn-search-page-results`]}>
      <Row>
        <Col>
          <h2 className={styles[`searchTermResults`]} lang={localizationInfo.language}>
            {formatCommonMessage({ id: 'search.result_for', defaultMessage: 'Results for' })} <q>{searchTerm}</q>
          </h2>
        </Col>
      </Row>

      {!isSearchResultsLoading ? (
        <div>
          {showFeaturedItem && featuredProduct && <FeaturedProductForSearch featuredProduct={featuredProduct} />}
          {showFeaturedItem && featuredCategoryContentBlocks && (
            <div className={styles['search-category-hero']}>
              <ContentBlocks data={featuredCategoryContentBlocks} />
            </div>
          )}

          {searchResults.map((searchItem) =>
            searchItem && router.query?.facet ? (
              <Fragment key={`search-hero${searchItem.id}`}>
                {
                  //@ts-ignore
                  router.query?.facet === fullFilters[searchMenus.LEARN_EXPLORE] && ( // TODO: Parts and support design will remain
                    <LearnAndMoreForSearch searchItem={searchItem} />
                  )
                }
                {
                  //@ts-ignore
                  router.query?.facet === fullFilters[searchMenus.PRESS_ROOM] && (
                    <PressRoomForSearch searchItem={searchItem} />
                  )
                }
                {
                  //@ts-ignore
                  router.query?.facet === fullFilters[searchMenus.OTHER] && <OtherForSearch searchItem={searchItem} />
                }
                {
                  //@ts-ignore
                  router.query?.facet === fullFilters[searchMenus.PDF] && <OtherForSearch searchItem={searchItem} />
                }
                {
                  //@ts-ignore
                  (router.query?.facet === fullFilters[searchMenus.RESULT_OVERVIEW] || //@ts-ignore
                    router.query?.facet === fullFilters[searchMenus.PRODUCT] || //@ts-ignore
                    router.query?.facet === fullFilters[searchMenus.ACCESSORIES]) && (
                    <ProductTilesForSearch searchItem={searchItem} />
                  )
                }
              </Fragment>
            ) : (
              <ProductTilesForSearch key={`search-hero${searchItem.id}`} searchItem={searchItem} />
            ),
          )}
        </div>
      ) : (
        <SearchListLoading />
      )}

      {searchInfo?.current_page && !isFacetLoading && (
        <div className={styles[`searchPaginationContainer`]}>
          <Pagination
            pagination={{
              total: searchInfo?.total_result_count,
              limit: searchInfo.per_page,
              offset: Number(router.query?.offset) || 0,
            }}
            onLoading={(value) => setIsPaginationLoading(value)}
            showLoader={false}
          />
        </div>
      )}
    </Container>
  ) : null;
};

export default SearchList;
