import { useRouter } from 'next/router';
import classNames from 'classnames';
import { CloseButton } from 'react-bootstrap';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useFormat } from 'helpers/hooks/useFormat';
import { ImageHelpers } from 'helpers/imageHelpers';
import {
  getVariantLowerPrice,
  getProductDiscountsByPosition,
  getVariantLowestPrice,
  hasMultiplePromoMessages,
} from 'helpers/productHelpers';
import { useAccount } from 'frontastic';
import useTableFields from './hooks/useTableFields';
import styles from './Item.module.scss';
import { CurrencyHelpers } from '../../../../../helpers/currencyHelpers';
import { useLocale } from '../../../../../helpers/hooks/useLocale';
import { TNikonProduct, TNikonVariant } from '../../../../../types/TNikonProduct';
import { tabsEventKey } from '../../../../contentstack/ProductOverviewPage/Brow/types/ETabKeys';
import RatingStars from '../../../../nikon-ui/atoms/RatingStars';
import Collapse, { useCollapseContext } from '../../../atoms/collapse/Collapse';
import useProductListItem from '../hooks/useProductListItem';
import { useProductList } from '../product-list/context';
import { getPromoMessage, getVariantPLP, optionsAvailable } from '../product-tile/utils';

interface ProductTableItemProps {
  product: TNikonProduct;
  getProductGTMEventData: (product: TNikonProduct, index?: number) => void;
  index: number;
}

const ProductTableItem = ({ product, getProductGTMEventData, index }: ProductTableItemProps) => {
  const router = useRouter();
  const { account } = useAccount();
  const { setVariantById, imageUnavailable, selectedVariant, swatchImages, isSale, isNew } =
    useProductListItem(product);
  const { locale, isUsaStore, isCanadaStore } = useLocale();
  const { tableViewColumns } = useProductList();
  const { totalFields, isAnyFieldHidden } = useTableFields();
  const { formatMessage } = useFormat({ name: 'product' });

  const variant = getVariantPLP(product.variants);

  const { open, toggleOpenClose } = useCollapseContext();

  const currentOptionsAvailable = optionsAvailable(product.variants);

  const promoMessage = getPromoMessage(product.variants);

  const variantLowerPrice = getVariantLowerPrice(product.variants);

  const haveMultipleVariants = product.variants.length > 1;
  const variantDiscountedPrice = variantLowerPrice.discountedPrice;
  const customBasePrice = variantLowerPrice.price.custom?.fields?.basePrice;
  const price = customBasePrice || variantLowerPrice?.price;
  const discountedPrice = customBasePrice
    ? variantDiscountedPrice // This is when the product have both discounts configured, a discount price and a marketing copy discount
      ? variantDiscountedPrice
      : variantLowerPrice?.price
    : variantDiscountedPrice || undefined;
  const startingAtPrice = discountedPrice || price;

  const marketingCopy = variantLowerPrice.price.custom?.fields?.marketingCopy;
  const marketingCopyDiscount = selectedVariant?.price?.custom?.fields?.basePrice;

  const browMessage =
    variantLowerPrice.discounts && getProductDiscountsByPosition(variantLowerPrice.discounts[0])?.brow;

  const isShowDiscount = Boolean(discountedPrice && (isUsaStore || isCanadaStore) && !haveMultipleVariants);

  const isShowMultiplePromoMessage = Boolean(
    (isUsaStore || isCanadaStore) && hasMultiplePromoMessages(product.variants),
  );
  const isShowMarketingCopy = isShowDiscount && !isShowMultiplePromoMessage && marketingCopy;
  const isShowBrowMessage = isShowDiscount && !isShowMultiplePromoMessage && browMessage;
  const isShowYouSaveMessage =
    isShowDiscount &&
    !isShowMultiplePromoMessage &&
    marketingCopyDiscount &&
    !isShowMarketingCopy &&
    !isShowBrowMessage;

  const getSuffix = (key: string) => {
    const column = tableViewColumns?.find((item) => item.value === key);
    return column?.suffix || '';
  };

  const getPrefix = (key: string) => {
    const column = tableViewColumns?.find((item) => item.value === key);
    return column?.prefix || '';
  };

  const getPrice = (variants: TNikonVariant[]): string => {
    if (variants.length === 1) {
      return CurrencyHelpers.formatForCurrency(startingAtPrice, router.locale);
    }

    return formatMessage({
      id: 'product.startingAt',
      defaultMessage: `Starting at {price}`,
      values: { price: CurrencyHelpers.formatForCurrency(startingAtPrice, locale) },
    });
  };

  const formatValueOrDefault = (value: unknown, defaultValue = '-') => {
    return value || defaultValue;
  };

  const formatValueOrDefaultWithPrefixes = (prefix: string, value: unknown, suffix: string) => {
    if (!value) return '-';

    return `${prefix}${value}${suffix}`;
  };

  const handleOnClickLearnMore = () => {
    sendGTMEvent({
      event: 'select_item',
      hit_time_stamp: Date.now(),
      userId: account?.accountId,
      userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      ecommerce: {
        actionField: { list: 'Product list', view: 'table' },
        items: [getProductGTMEventData(product, index)],
      },
    });
  };

  return (
    <div className={styles['container']}>
      <button
        onClick={toggleOpenClose}
        className={classNames({ [styles.opened]: open })}
        style={{ gridTemplateColumns: `repeat(${totalFields}, 1fr)` }}
      >
        {!isAnyFieldHidden && (
          <div>
            {formatValueOrDefaultWithPrefixes(
              getPrefix('mountType'),
              variant.attributes.mountType?.[0].label,
              getSuffix('mountType'),
            )}
          </div>
        )}
        <div>
          {!variant.attributes.focalLengthMin &&
            !variant.attributes.focalLengthMax &&
            variant.attributes.focalLength &&
            formatValueOrDefaultWithPrefixes(
              getPrefix('focalLength'),
              variant.attributes.focalLength,
              getSuffix('focalLength'),
            )}
          {variant.attributes.focalLengthMin &&
            `${getPrefix('focalLengthMin')}${variant.attributes.focalLengthMin}${getSuffix('focalLengthMin')}`}
          {variant.attributes.focalLengthMin && variant.attributes.focalLengthMax && '-'}
          {variant.attributes.focalLengthMax &&
            `${getPrefix('focalLengthMax')}${variant.attributes.focalLengthMax}${getSuffix('focalLengthMax')}`}
          {!variant.attributes.focalLengthMin &&
            !variant.attributes.focalLengthMax &&
            !variant.attributes.focalLength &&
            '-'}
        </div>
        <div>
          {formatValueOrDefaultWithPrefixes(
            getPrefix('maximumAperture'),
            variant.attributes.maximumAperture,
            getSuffix('maximumAperture'),
          )}
        </div>
        <div className={styles['name']}>
          {isSale && <span className={styles.newOrSale}>{formatMessage({ id: 'sale', defaultMessage: 'SALE' })}</span>}
          {!isSale && isNew && (
            <span className={styles.newOrSale}>{formatMessage({ id: 'new', defaultMessage: 'NEW' })}</span>
          )}
          {formatValueOrDefault(product.name, 'No-Name')}
        </div>
        <div>{formatValueOrDefault(variant.attributes.format?.[0].label)}</div>
        <div>{formatValueOrDefault(variant.attributes.focusMode?.[0].label)}</div>
        {isUsaStore && <div>{formatValueOrDefault(getPrice(product.variants))}</div>}
        {isCanadaStore && (
          <div className="flex flex-col">
            {discountedPrice && (
              <>
                <div className="font-bold">
                  {formatMessage({ id: 'sale.now', defaultMessage: 'Now' })}{' '}
                  {CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}
                </div>
                <span>
                  {formatMessage({ id: 'sale.was', defaultMessage: 'Reg.' })}{' '}
                  {CurrencyHelpers.formatForCurrency(price, router.locale)}
                </span>
              </>
            )}
            {!discountedPrice && price?.centAmount && <div>{formatValueOrDefault(getPrice(product.variants))}</div>}
          </div>
        )}
      </button>
      <Collapse open={open}>
        <div className={styles['quick-view']}>
          <div className={styles['quick-view-header']}>
            <CloseButton onClick={toggleOpenClose} />
          </div>
          <div className={styles['quick-view-content']}>
            <div>
              <img
                //src={selectedVariant.representativeImage || ''}
                src={
                  selectedVariant.representativeImage
                    ? ImageHelpers.modifiedCTImageUrl(selectedVariant.representativeImage, 'medium')
                    : ''
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = imageUnavailable;
                }}
                loading="lazy"
              />
              <div className={styles['variant-swatches']}>
                {swatchImages.map((item, key) => (
                  <div
                    key={key}
                    className={classNames({ [styles['selected']]: item.id === selectedVariant.id })}
                    onClick={() => setVariantById(item.id)}
                  >
                    <img src={item.image} loading="lazy" />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div>
                <div className={styles['name']}>
                  {isSale && (
                    <span className={styles.newOrSale}>{formatMessage({ id: 'sale', defaultMessage: 'SALE' })}</span>
                  )}
                  {!isSale && isNew && (
                    <span className={styles.newOrSale}>{formatMessage({ id: 'new', defaultMessage: 'NEW' })}</span>
                  )}
                  <span className={styles['product-name']}>{formatValueOrDefault(product.name, 'No-Name')}</span>
                </div>
                {(isUsaStore || isCanadaStore) && (
                  <div className={styles['sku']}>
                    {formatMessage({ id: 'product', defaultMessage: 'Product' })} {variant.sku}
                  </div>
                )}
                <div className={styles['description']}>{product.description}</div>
              </div>
              <div>
                <div className={styles['price-container']}>
                  {price?.centAmount && haveMultipleVariants && (
                    <div className={styles['price']}>
                      {formatMessage({
                        id: 'product.startingAt',
                        defaultMessage: 'Starting at {price}',
                        values: {
                          price: CurrencyHelpers.formatForCurrency(
                            getVariantLowestPrice(product.variants).price,
                            router.locale,
                          ),
                        },
                      })}
                    </div>
                  )}

                  {price?.centAmount && isShowDiscount && discountedPrice && (
                    <>
                      {isUsaStore && (
                        <>
                          <span className={styles['price-was']}>
                            {CurrencyHelpers.formatForCurrency(price, router.locale)}
                          </span>
                          <div className={styles['price']}>{CurrencyHelpers.formatForCurrency(discountedPrice)}</div>
                        </>
                      )}
                      {isCanadaStore && (
                        <>
                          <div className="font-bold">
                            {formatMessage({ id: 'sale.now', defaultMessage: 'Now' })}{' '}
                            {CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}
                          </div>
                          <span>
                            {formatMessage({ id: 'sale.was', defaultMessage: 'Reg.' })}{' '}
                            {CurrencyHelpers.formatForCurrency(price, router.locale)}
                          </span>
                        </>
                      )}

                      <div className={styles['you-save']}>
                        {isShowYouSaveMessage && (
                          <>
                            {formatMessage({
                              id: 'product.youSave',
                              defaultMessage: 'You Save',
                            })}
                            &nbsp;
                            {CurrencyHelpers.formatForCurrency(
                              CurrencyHelpers.subtractCurrency(price, discountedPrice),
                              router.locale,
                            )}
                          </>
                        )}
                        {isShowMultiplePromoMessage &&
                          formatMessage({
                            id: 'product.multiplePromotions',
                            defaultMessage: 'Multiple Promotions Available',
                          })}

                        {isShowMarketingCopy && marketingCopy}
                        {isShowBrowMessage && browMessage}
                      </div>
                    </>
                  )}

                  {price?.centAmount && haveMultipleVariants && isShowMultiplePromoMessage && (
                    <div className={styles['you-save']}>
                      {formatMessage({
                        id: 'product.multiplePromotions',
                        defaultMessage: 'Multiple Promotions Available',
                      })}
                    </div>
                  )}

                  {price?.centAmount && !haveMultipleVariants && !isShowDiscount && !isShowMultiplePromoMessage && (
                    <div className={styles['price']}>{CurrencyHelpers.formatForCurrency(price, router.locale)}</div>
                  )}
                </div>

                {currentOptionsAvailable.show && (
                  <div>
                    {formatMessage({
                      id: 'product.options',
                      defaultMessage: '{optionsAmount} Options Available',
                      values: { optionsAmount: currentOptionsAvailable.count ?? '' },
                    })}
                  </div>
                )}
                {Boolean(promoMessage) && <div className={styles['promo-message']}>{promoMessage}</div>}
                {Boolean(product?.reviewRatingStatistics) && (
                  <a className={styles['rating']} href={`${product._url}/overview#${tabsEventKey.RATING_AND_REVIEW}`}>
                    <RatingStars product={product} variant={variantLowerPrice} locale={locale} />
                  </a>
                )}
                <a
                  className={classNames('btn-yellow nk-btn-smooth-rounded', styles['learn-more'])}
                  href={`${product._url}/overview`}
                  onClick={handleOnClickLearnMore}
                >
                  {formatMessage({ id: 'product.learnMore', defaultMessage: 'Learn More' })}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default ProductTableItem;
