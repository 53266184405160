import classNames from 'classnames';
import styles from './View.module.scss';
import { useProductList } from '../../../../context';

const View = () => {
  const { view, useListView, useGridView, useTableView, isLensesSection } = useProductList();

  return (
    <div className={styles['container']}>
      <button className={classNames({ [styles['selected']]: view === 'grid' })} title="grid-view" onClick={useGridView}>
        <div className={styles['grid-icon']} />
      </button>
      <button className={classNames({ [styles['selected']]: view === 'list' })} title="list-view" onClick={useListView}>
        <div className={styles['list-icon']} />
      </button>
      {isLensesSection && (
        <button
          className={classNames({ [styles['selected']]: view === 'table' })}
          title="table-view"
          onClick={useTableView}
        >
          <div className={styles['table-icon']} />
        </button>
      )}
    </div>
  );
};

export default View;
