import { useCallback, useEffect, useState } from 'react';
import useSWR from 'swr';
import useDebounce from 'helpers/hooks/useDebounce';
import { Locales, getSwiftTypeUrl } from 'helpers/utils/swiftType';
import { TNikonProduct } from 'types/TNikonProduct';

interface UseTypeAheadReturn {
  searchTerm: string;
  searchResults: SearchResults[];
  handleSearchTermChange: (newTerm: string) => void;
  isLoading: boolean;
  fetchNextPage: () => Promise<any>;
  totalResults: number;
}

interface SearchResults {
  id: string;
  name: string;
}

const fields = ['id', 'niProductName'];
const filters = { type: 'Products', niProductStatus: 'archived' };

const mapSearchResults = (records: any[]): SearchResults[] => {
  return records.map((record) => {
    let name = record.niProductName;
    if (Array.isArray(record.niProductName)) {
      name = record.niProductName.reduce((shortest: string, current: string) => {
        return current.length < shortest.length ? current : shortest;
      }, record.niProductName[0]);
    }
    return {
      id: record.id,
      name,
    };
  });
};

const useTypeAheadArchived = (locale: Locales, archivedProducts?: TNikonProduct[]): UseTypeAheadReturn => {
  const [page, setPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [results, setResults] = useState<SearchResults[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, isDebounceLoading] = useDebounce(searchTerm, 150, '');

  useEffect(() => {
    if (archivedProducts && Boolean(debouncedSearchTerm.length) && !isDebounceLoading) {
      const filteredSearchResults: SearchResults[] = archivedProducts
        .filter((product) => product.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()))
        .map((product) => ({
          id: product.productId,
          name: product.name,
        }));
      if (filteredSearchResults) {
        setResults(filteredSearchResults);
        setTotalResults(filteredSearchResults.length);
      } else {
        setResults([]);
      }
    } else {
      setResults([]);
    }
  }, [archivedProducts, debouncedSearchTerm, isDebounceLoading]);

  const fetchNextPage = useCallback(async () => {
    const swiftTypeEndpoint = getSwiftTypeUrl(
      { query: debouncedSearchTerm, fields, filtersSuggest: filters, limit: 8, page: page + 1 },
      locale,
    );
    const data = await fetch(swiftTypeEndpoint || '').then((res) => res.json());
    setPage(page + 1);
    return mapSearchResults(data?.records?.page);
  }, [page, debouncedSearchTerm]);

  const handleSearchTermChange = (newTerm: string) => {
    setSearchTerm(newTerm);
  };

  return {
    searchTerm,
    searchResults: results,
    handleSearchTermChange,
    isLoading: isDebounceLoading,
    fetchNextPage,
    totalResults,
  };
};

export default useTypeAheadArchived;
