import React from 'react';
import classNames from 'classnames';
import styles from '../ArchivedSearch.module.scss';

interface SearchItemProps {
  name: string;
  isSelected: boolean;
  onEvent?: (name: string) => void;
  onClick?: () => void;
}

const SearchItem = ({ name, isSelected, onEvent, onClick }: SearchItemProps) => {
  return (
    <li
      className={classNames(styles['search-results-list-item'], { [styles.selected]: isSelected })}
      onMouseMove={() => onEvent?.(name)}
      onClick={() => {
        onEvent?.(name);
        onClick?.();
      }}
    >
      <h4 className={classNames(styles['search-results-list-item-title'], 'truncate')}>{name}</h4>
    </li>
  );
};

export default SearchItem;
