import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Form } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { Locales } from 'helpers/utils/swiftType';
import useSwifTypeFacets from 'frontastic/hooks/useSwifTypeFacets';
import styles from './SearchFilter.module.scss';
import { useSearch } from '../../context';
import { fullFilters, fullReverseFilters, searchMenus } from '../SearchBrowBar/constant';

const SearchFilter = () => {
  const router = useRouter();
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const { searchTerm, handleSearchFilter, changePage, setIsFacetLoading } = useSearch();
  const { isLoading, searchInfo, handleSearchTermChange } = useSwifTypeFacets(
    router?.locale?.replace('-', '_') as Locales,
  );
  const [show, setShow] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(
    //@ts-ignore
    router.query?.facet ? fullReverseFilters[router.query?.facet] : searchMenus.RESULT_OVERVIEW,
  );
  const [isBiggerThanTabletSize] = useMediaQuery(tablet);

  const setSelector = (value: string) => (selectedMenu === value ? styles.selected : '');

  const dropDownRef = useRef(null);

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (searchTerm) {
      handleSearchTermChange(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    if (router.query?.facet && typeof router.query.facet === 'string') {
      //@ts-ignore
      setSelectedMenu(fullReverseFilters[router.query.facet]);
      setIsFacetLoading(false);
      //@ts-ignore
      return router.query.facet === fullFilters[searchMenus.RESULT_OVERVIEW]
        ? handleSearchFilter(Object.values(fullFilters))
        : handleSearchFilter([router.query.facet]);
    }
  }, [router.query?.facet]);

  const handleFacets = (value: string) => {
    changePage(1);
    setIsFacetLoading(true);
    setSelectedMenu(value);
    //@ts-ignore
    router.push({ pathname: `/s/${searchTerm}`, query: { facet: fullFilters[value] } });
  };

  if (isLoading) {
    return (
      <>
        {isBiggerThanTabletSize ? (
          <Form className={`d-flex ${styles.searchMenu}`}>
            <div className={`${styles.fieldset} ${show && styles.show} `}>
              <span className={`${styles.facet} ${styles.selected}`}>
                {formatCommonMessage({
                  //@ts-ignore
                  id: `search.filter.${fullFilters[searchMenus.RESULT_OVERVIEW]}`,
                  defaultMessage: searchMenus.RESULT_OVERVIEW,
                })}{' '}
                {`(`}
                <img
                  src={`/images/icon_loading.gif`}
                  className={`${styles.loader} `}
                  alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
                />
                {`)`}
              </span>
            </div>
          </Form>
        ) : (
          <Form className={`d-flex ${styles.searchMenu}`}>
            <span className={`${styles.facet} ${styles.selected} `}>
              {' '}
              {formatCommonMessage({
                //@ts-ignore
                id: `search.filter.${fullFilters[selectedMenu]}`,
                defaultMessage: selectedMenu,
              })}{' '}
              {`(`}
              <img
                src={`/images/icon_loading.gif`}
                className={`${styles.loader} `}
                alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
              />
              {`)`}
            </span>
          </Form>
        )}
      </>
    );
  }

  return (
    <>
      {Boolean(searchInfo) &&
        (isBiggerThanTabletSize ? (
          <Form className={`d-flex ${styles.searchMenu}`}>
            <div className={`${styles.fieldset} ${show && styles.show} `}>
              {Boolean(searchInfo?.total_result_count) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.RESULT_OVERVIEW)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.RESULT_OVERVIEW)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.RESULT_OVERVIEW]}`,
                      defaultMessage: searchMenus.RESULT_OVERVIEW,
                    })}{' '}
                    {`(${searchInfo?.total_result_count})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Products) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.PRODUCT)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.PRODUCT)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.PRODUCT]}`,
                      defaultMessage: searchMenus.PRODUCT,
                    })}{' '}
                    {`(${searchInfo?.facets?.Products})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Accessories) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.ACCESSORIES)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.ACCESSORIES)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.ACCESSORIES]}`,
                      defaultMessage: searchMenus.ACCESSORIES,
                    })}{' '}
                    {`(${searchInfo?.facets?.Accessories})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Parts) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.Parts)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.Parts)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.Parts]}`,
                      defaultMessage: searchMenus.Parts,
                    })}{' '}
                    {`(${searchInfo?.facets?.Parts})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Press) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.PRESS_ROOM)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.PRESS_ROOM)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.PRESS_ROOM]}`,
                      defaultMessage: searchMenus.PRESS_ROOM,
                    })}{' '}
                    {`(${searchInfo?.facets?.Press})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.LE) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.LEARN_EXPLORE)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.LEARN_EXPLORE)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.LEARN_EXPLORE]}`,
                      defaultMessage: searchMenus.LEARN_EXPLORE,
                    })}{' '}
                    {`(${searchInfo?.facets?.LE})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Support) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.SUPPORT)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.SUPPORT)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.SUPPORT]}`,
                      defaultMessage: searchMenus.SUPPORT,
                    })}{' '}
                    {`(${searchInfo?.facets?.Support})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.PDF) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.PDF)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.PDF)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.PDF]}`,
                      defaultMessage: searchMenus.PDF,
                    })}{' '}
                    {`(${searchInfo?.facets?.PDF})`}
                  </button>
                </span>
              )}
              {Boolean(searchInfo?.facets?.Other) && (
                <span className={`${styles.facet} ${setSelector(searchMenus.OTHER)}`}>
                  <button type="button" onClick={() => handleFacets(searchMenus.OTHER)}>
                    {formatCommonMessage({
                      //@ts-ignore
                      id: `search.filter.${fullFilters[searchMenus.OTHER]}`,
                      defaultMessage: searchMenus.OTHER,
                    })}{' '}
                    {`(${searchInfo?.facets?.Other})`}
                  </button>
                </span>
              )}
            </div>
          </Form>
        ) : (
          <Form
            className={`d-flex ${styles.searchMenu}`}
            ref={dropDownRef}
            onClick={() => {
              if (!isBiggerThanTabletSize) {
                setShow(!show);
              } else {
                setShow(false);
              }
            }}
          >
            <span className={`${styles.facet} ${styles.selected} `}>
              {' '}
              {Boolean(searchInfo?.total_result_count) &&
                formatCommonMessage({
                  //@ts-ignore
                  id: `search.filter.${fullFilters[selectedMenu]}`,
                  defaultMessage: selectedMenu,
                })}{' '}
              {`(${
                selectedMenu === searchMenus.RESULT_OVERVIEW
                  ? searchInfo?.total_result_count //@ts-ignore
                  : searchInfo?.facets?.[fullFilters[selectedMenu]]
              })`}
            </span>
            <div className={`${styles.fieldset} ${show && styles.show} `}>
              {Boolean(searchInfo?.total_result_count) && selectedMenu !== searchMenus.RESULT_OVERVIEW && (
                <span className={`${styles.facet} `} onClick={() => handleFacets(searchMenus.RESULT_OVERVIEW)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.RESULT_OVERVIEW]}`,
                    defaultMessage: searchMenus.RESULT_OVERVIEW,
                  })}{' '}
                  {`(${searchInfo?.total_result_count})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Products) && selectedMenu !== searchMenus.PRODUCT && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.PRODUCT)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.PRODUCT]}`,
                    defaultMessage: searchMenus.PRODUCT,
                  })}{' '}
                  {`(${searchInfo?.facets?.Products})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Accessories) && selectedMenu !== searchMenus.ACCESSORIES && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.ACCESSORIES)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.ACCESSORIES]}`,
                    defaultMessage: searchMenus.ACCESSORIES,
                  })}{' '}
                  {`(${searchInfo?.facets?.Accessories})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Parts) && selectedMenu !== searchMenus.Parts && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.Parts)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.Parts]}`,
                    defaultMessage: searchMenus.Parts,
                  })}{' '}
                  {`(${searchInfo?.facets?.Parts})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Press) && selectedMenu !== searchMenus.PRESS_ROOM && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.PRESS_ROOM)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.PRESS_ROOM]}`,
                    defaultMessage: searchMenus.PRESS_ROOM,
                  })}{' '}
                  {`(${searchInfo?.facets?.Press})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.LE) && selectedMenu !== searchMenus.LEARN_EXPLORE && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.LEARN_EXPLORE)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.LEARN_EXPLORE]}`,
                    defaultMessage: searchMenus.LEARN_EXPLORE,
                  })}{' '}
                  {`(${searchInfo?.facets?.LE})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Support) && selectedMenu !== searchMenus.SUPPORT && (
                <span className={`${styles.facet} `} onClick={() => handleFacets(searchMenus.SUPPORT)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.SUPPORT]}`,
                    defaultMessage: searchMenus.SUPPORT,
                  })}{' '}
                  {`(${searchInfo?.facets?.Support})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.PDF) && selectedMenu !== searchMenus.PDF && (
                <span className={`${styles.facet} `} onClick={() => handleFacets(searchMenus.PDF)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.PDF]}`,
                    defaultMessage: searchMenus.PDF,
                  })}{' '}
                  {`(${searchInfo?.facets?.PDF})`}
                </span>
              )}
              {Boolean(searchInfo?.facets?.Other) && selectedMenu !== searchMenus.OTHER && (
                <span className={`${styles.facet}`} onClick={() => handleFacets(searchMenus.OTHER)}>
                  {formatCommonMessage({
                    //@ts-ignore
                    id: `search.filter.${fullFilters[searchMenus.OTHER]}`,
                    defaultMessage: searchMenus.OTHER,
                  })}{' '}
                  {`(${searchInfo?.facets?.Other})`}
                </span>
              )}
            </div>
          </Form>
        ))}
    </>
  );
};

export default SearchFilter;
