import { ICategoryPage } from 'interfaces/ICategoryPage';
import { IContentBlocks } from 'interfaces/IContentBlocks';
import { TCategoryPage } from 'types/TCategoryPage';
import { PageMetaDataDTO } from './CommonFieldsDTO';
import { ContentBlocksDTO } from './ContentBlocksDTO';

export const CategoryPageDTO = (data: ICategoryPage): TCategoryPage | undefined => {
  if (!data) {
    return undefined;
  }

  return {
    categorySlug: data.category_slug,
    contentBlocks: ContentBlocksDTO(data.content_blocks_top as IContentBlocks),
    contentTypeUid: 'category_page',
    fileName: data.file_name,
    metaData: PageMetaDataDTO(data.meta_data),
    uid: data.uid,
  };
};
