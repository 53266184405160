import { createContext, useContext, useState } from 'react';
import { Collapse as ReactCollapse } from 'react-bootstrap';
import { CollapseProps, CollapseProviderProps } from './interfaces';

export const CollapseContext = createContext({ open: false, toggleOpenClose: () => {} });

export const useCollapseContext = () => useContext(CollapseContext);

export const CollapseProvider = ({ children }: CollapseProviderProps) => {
  const [open, setOpen] = useState(false);

  const toggleOpenClose = () => setOpen((v) => !v);

  return <CollapseContext.Provider value={{ toggleOpenClose, open }}>{children}</CollapseContext.Provider>;
};

const Collapse = ({ open, children }: CollapseProps) => {
  return <ReactCollapse in={open}>{children}</ReactCollapse>;
};

export default Collapse;
