import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Product } from '../../../../../../../types/product';
import { TNikonProduct } from '../../../../../../../types/TNikonProduct';
import LoadingIcon from '../../../../../atoms/button/loadingIcon';
import { CollapseProvider } from '../../../../../atoms/collapse/Collapse';
import ProductTableItem from '../../../product-table/Item';

const ListProductsTableView = ({
  products,
  getProductGTMEventData,
}: {
  products: Product[];
  getProductGTMEventData: (product: TNikonProduct, index?: number) => void;
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };
    const handleComplete = () => {
      setLoading(false);
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.events]);

  return (
    <>
      {loading && (
        <div className="flex items-center justify-center p-30">
          <LoadingIcon className="fill-gray-700" />
        </div>
      )}
      {!loading &&
        products.map((product, i) => (
          <CollapseProvider key={product.productId}>
            <ProductTableItem
              product={product as unknown as TNikonProduct}
              getProductGTMEventData={getProductGTMEventData}
              index={i}
            />
          </CollapseProvider>
        ))}
    </>
  );
};

export default ListProductsTableView;
