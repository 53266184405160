import { appLocales } from 'helpers/constants/appLocales';
import { E_FIELDS } from './interfaces';

export const FIELDS = [
  { key: E_FIELDS.LENS_MOUNT, value: 'Lens Mount', hideOn: ['dslr-lenses'], attributesKeys: ['mountType'] },
  {
    key: E_FIELDS.FOCAL_LENGTH,
    value: 'Focal Length',
    attributesKeys: ['focalLength', 'focalLengthMin', 'focalLengthMax'],
  },
  {
    key: E_FIELDS.APERTURE,
    value: 'Aperture',
    attributesKeys: ['maximumAperture', 'minimumAperture', 'apertureRange'],
  },
  { key: E_FIELDS.LENS_NAME, value: 'Lens Name', attributesKeys: ['name'] },
  { key: E_FIELDS.LENS_FORMAT, value: 'Lens Format', attributesKeys: ['format'] },
  { key: E_FIELDS.FOCUS_TYPE, value: 'Focus Type', attributesKeys: ['focusMode'] },
  {
    key: E_FIELDS.PRICE,
    value: 'Price',
    availableLocales: [appLocales.EN_US, appLocales.EN_CA, appLocales.FR_CA],
    attributesKeys: ['price'],
  },
];
