import { useCallback } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { tablet } from 'helpers/utils/screensizes';
import styles from './FilterHeader.module.scss';
import View from './View/view';
import useMediaQuery from '../../../../../../../../helpers/hooks/useMediaQuery';
import Pagination from '../../../../../../../nikon-ui/atoms/pagination';
import { SelectDropdown } from '../../../../../../atoms/dropdown/select-dropdown';
import { useProductList } from '../../../context';

const ItemsPerPageDropdownItems = [
  { label: '12', value: '12' },
  { label: '24', value: '24' },
  { label: '36', value: '36' },
  { label: 'All', value: 'all' },
];

const FilterHeader = () => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
  const {
    view,
    totalItems,
    activeLimit,
    offset,
    setLimit,
    sortDropdownItems,
    sortOption,
    getSortByKey,
    replaceSort,
    goToTopResults,
  } = useProductList();
  const screenSize = useMediaQuery()[0];
  const isMobile = screenSize < tablet;

  const onSortChange = useCallback(
    (key: string) => {
      const sort = getSortByKey(key);
      if (sort) {
        replaceSort(sort, { sortBy: key });
      }
    },
    [replaceSort, getSortByKey],
  );

  const paginationOptions = ItemsPerPageDropdownItems.filter((item) => {
    return item.value === 'all' || totalItems >= Number(item.value);
  }).map((item) => {
    return {
      label: item.value === 'all' ? formatMessage({ id: item.label, defaultMessage: item.label }) : item.label,
      value: item.value,
    };
  });

  const resultsText =
    totalItems === 1
      ? formatMessage({ id: 'plp.filters.result', defaultMessage: '1 Result' })
      : formatMessage({
          id: 'plp.filters.results',
          defaultMessage: `${totalItems} Results`,
          values: { count: totalItems },
        });

  const handleOnChangeItemsPerPage = (value: string) => {
    goToTopResults();
    if (value === 'all') {
      setLimit(totalItems);
    } else {
      setLimit(Number(value));
    }
  };

  const getItemsPerPageSelectedValue = () => {
    const dropdownLimitSelectedItem = ItemsPerPageDropdownItems.find((item) => {
      if (Number(item.value) === activeLimit) {
        return true;
      }
      if (activeLimit >= totalItems && item.value === 'all') {
        return true;
      }
    });
    return dropdownLimitSelectedItem?.value;
  };

  const isPaginationRequired = activeLimit < totalItems;

  return (
    <div className={styles['container']}>
      <div className={styles['results']}>{resultsText}</div>
      <div className={styles['filters']}>
        {view !== 'table' && Boolean(sortDropdownItems?.length) && (
          <div className={styles['title']}>
            <SelectDropdown
              title={formatOrdersMessage({ id: 'sort', defaultMessage: 'Sort By:' })}
              options={sortDropdownItems}
              onChange={(value) => {
                goToTopResults();
                onSortChange(value);
              }}
              defaultValue={sortOption}
            />
          </div>
        )}
        <div className={styles['title']}>
          <span>{formatMessage({ id: 'view', defaultMessage: 'View' })}:</span>
          <View />
        </div>
        {!isMobile && view !== 'table' && (
          <div className={styles['title']}>
            <SelectDropdown
              options={paginationOptions}
              title={`${formatMessage({ id: 'plp.filters.itemsPerPage', defaultMessage: 'Items per page' })}:`}
              onChange={handleOnChangeItemsPerPage}
              defaultValue={getItemsPerPageSelectedValue()}
            />
          </div>
        )}
        {!isMobile && isPaginationRequired && (
          <div className={styles['title']}>
            <Pagination
              pagination={{ total: totalItems, limit: activeLimit, offset }}
              onLoading={goToTopResults}
              scroll={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
