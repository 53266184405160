import classNames from 'classnames';
import styles from './Header.module.scss';
import { E_FIELDS } from './interfaces';
import { useProductList } from '../../product-list/context';

const Header = () => {
  const { fieldSelected, setFieldSelected, tableFields, getTableViewSortByKey, replaceSort, tableDefinitions } =
    useProductList();

  const handleOnClick = (field: { key: E_FIELDS }) => {
    let direction: 'up' | 'down' = 'up';
    if (fieldSelected === field.key) {
      direction = tableFields.toggleDirection();
    } else {
      setFieldSelected(field.key);
      tableFields.setDirection('up');
    }

    const sort = getTableViewSortByKey(field.key, direction);
    replaceSort(sort, { tableSortBy: field.key });
  };

  return (
    <div
      className={classNames(styles['container'])}
      style={{ gridTemplateColumns: `repeat(${tableFields.totalFields}, 1fr)` }}
    >
      {tableFields.fieldsToRender.map((field) => {
        const tableDefinitionIndex =
          tableDefinitions?.findIndex(({ key }) => field.attributesKeys?.includes(key)) ?? -1;
        const subscriptNumber = tableDefinitionIndex >= 0 ? tableDefinitionIndex + 1 : 0;

        return (
          <div
            className={classNames({ [styles['selected']]: fieldSelected === field.key })}
            key={field.key}
            onClick={() => handleOnClick(field)}
          >
            <span>
              {field.value}
              {subscriptNumber ? <sup>{subscriptNumber}</sup> : null}
            </span>

            <span
              className={classNames(
                { [styles['sort-icon-selected']]: fieldSelected === field.key },
                styles[tableFields.direction],
              )}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Header;
