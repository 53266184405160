import { tablet } from 'helpers/utils/screensizes';
import NarrowByDesktop from './desktop';
import NarrowByMobile from './mobile';
import styles from './NarrowBy.module.scss';
import useMediaQuery from '../../../../../../../../../helpers/hooks/useMediaQuery';

const NarrowBy = () => {
  const screenSize = useMediaQuery()[0];
  const isMobile = screenSize < tablet;

  return (
    <div className={styles['container']}>
      {!isMobile && <NarrowByDesktop />}
      {isMobile && <NarrowByMobile />}
    </div>
  );
};

export default NarrowBy;
