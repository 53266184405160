import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Form } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop } from 'helpers/utils/screensizes';
import { Locales } from 'helpers/utils/swiftType';
import useTypeAhead from 'frontastic/hooks/useTypeAhead';
import { fullFilters, searchMenus } from './constant';
import styles from './SearchBrowBar.module.scss';
import { useSearch } from '../../context';
import SearchFilter from '../search-filters';
import SearchItem from '../SearchItem';

const SearchBrowBar = () => {
  const router = useRouter();
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const { query: searchQuery } = useSearch();
  const [isDesktop] = useMediaQuery(desktop);
  const [isSubmitted, setSubmitted] = useState(false);
  const [value, setValue] = useState(searchQuery);
  const resultsListRef = useRef<HTMLOListElement | null>(null);
  const searchBoxRef = useRef<HTMLInputElement | null>(null);
  const { searchTerm, searchResults, handleSearchTermChange, isLoading } = useTypeAhead(
    router?.locale?.replace('-', '_') as Locales,
  );
  const shouldGetSwifType = isDesktop;
  const isResultsListVisible = shouldGetSwifType && ((isLoading && searchTerm) || searchResults.length > 0);

  useEffect(() => {
    if (searchQuery && searchBoxRef.current) {
      searchBoxRef.current.value = searchQuery;
      setValue(searchQuery);
    }
  }, [searchQuery]);

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    if (!resultsListRef?.current || !searchBoxRef?.current || !isResultsListVisible || !searchResults.length) return;

    const resultsList = [...resultsListRef.current.querySelectorAll('a')];
    const firstResult = resultsList[0];
    const lastResult = resultsList[resultsList.length - 1];
    const curResult = e.target as HTMLAnchorElement | HTMLInputElement;
    const isSearchElement = curResult === searchBoxRef.current;
    let nextResult, prevResult;
    setSubmitted(false);

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (isSearchElement) nextResult = firstResult;
        else if (curResult === lastResult) nextResult = searchBoxRef.current;
        else nextResult = resultsList[resultsList.indexOf(curResult as HTMLAnchorElement) + 1];

        nextResult.focus({ preventScroll: true });
        break;

      case 'ArrowUp':
        e.preventDefault();
        if (isSearchElement) prevResult = lastResult;
        else if (curResult === firstResult) prevResult = searchBoxRef.current;
        else prevResult = resultsList[resultsList.indexOf(curResult as HTMLAnchorElement) - 1];

        prevResult.focus({ preventScroll: true });
        break;

      case 'Enter':
        if (!isSearchElement) {
          router.push((curResult as HTMLAnchorElement).href);
          clearSearch();
        }
        break;
    }
  };

  const clearSearch = () => {
    handleSearchTermChange('');
    setValue('');
    if (searchBoxRef.current) {
      searchBoxRef.current.value = '';
      searchBoxRef.current.blur();
    }
  };

  const handleOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchBoxRef?.current?.value) {
      router.push({
        pathname: `/s/${searchBoxRef?.current?.value || ''}`, //@ts-ignore
        query: { facet: fullFilters[searchMenus.RESULT_OVERVIEW] },
      });
      setSubmitted(true);
      searchBoxRef.current.blur();
    }
  };
  const handleOnClick = (e: React.MouseEvent) => {
    if (shouldGetSwifType && searchBoxRef.current && searchBoxRef.current.value) {
      handleSearchTermChange(searchBoxRef.current.value);
      setSubmitted(false);
    }
  };

  return (
    <div className={styles.searchBrowBar}>
      <Form className={`d-flex ${styles.searchField}`} onSubmit={handleOnSubmit}>
        <Form.Control
          type="search"
          placeholder={`${formatCommonMessage({ id: 'search.placeholder', defaultMessage: 'Search' })}`}
          aria-label="Search"
          onChange={(e) => {
            setValue(e.target.value);
            if (shouldGetSwifType) {
              handleSearchTermChange(e.target.value);
            }
          }}
          ref={searchBoxRef}
          onKeyDown={handleOnKeyDown}
          onClick={handleOnClick}
        />
        {value && (
          <button
            type="reset"
            className={`ss-delete ${styles.clearSearch}`}
            aria-label="Clear Search"
            onClick={clearSearch}
          ></button>
        )}

        <button type="submit" className={`${styles.submitSearch} ${value ? styles.active : ''}`}>
          <img src={`/images/search.png`} alt={formatCommonMessage({ id: 'search.icon', defaultMessage: 'Search' })} />
        </button>

        {/* this is auto suggestion */}
        {isResultsListVisible && !isSubmitted && (
          <div className={styles['search-results-container']}>
            <header className={styles['search-results-title']}>
              {formatCommonMessage({ id: 'search.resultsTitle', defaultMessage: 'Matching products or accessories' })}
            </header>

            {isLoading && (
              <div className={styles['search-results-loader-container']}>
                <img
                  src={`/images/icon_loading.gif`}
                  alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
                />
              </div>
            )}

            <ol role="listbox" className={styles['search-results-list']} ref={resultsListRef}>
              {searchResults.map((result) => (
                <SearchItem
                  key={result.id}
                  image={result.image}
                  title={result.title}
                  description={result.description}
                  url={result.url}
                  onKeyDown={handleOnKeyDown}
                />
              ))}
            </ol>
          </div>
        )}
      </Form>
      <SearchFilter />
    </div>
  );
};

export default SearchBrowBar;
