import Link from 'next/link';
import { useRouter } from 'next/router';
import { ImageHelpers } from 'helpers/imageHelpers';
import styles from './Product.module.scss';
import { IMAGE_SOURCE } from '../../../../../../../../helpers/constants/imageSources';
import { TNikonProduct } from '../../../../../../../../types/TNikonProduct';

interface Props {
  product: TNikonProduct;
}

const Product = ({ product }: Props) => {
  const router = useRouter();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);

  return (
    <div>
      <Link href={`${product._url}/overview` || '#'}>
        <div className={styles['container']}>
          <div className={styles['image-container']}>
            <div>
              <img
                src={
                  ImageHelpers.modifiedCTImageUrl(
                    product.variants[0]?.representativeImage || product.variants[0].images[0],
                    'medium',
                  ) || imageUnavailable
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = imageUnavailable;
                }}
                loading="lazy"
              />
            </div>
          </div>
          <div className={styles['separator']}>
            <hr />
          </div>
          <div className={styles['title']}>{product.name}</div>
        </div>
      </Link>
    </div>
  );
};

export default Product;
