import { type RefObject, useState, useEffect, useCallback } from 'react';

interface dragToScrollData {
  ref: RefObject<HTMLDivElement>;
  isScrollActive: boolean;
  handleMouseDown: (e: React.MouseEvent) => void;
  handleMouseUp: VoidFunction;
  handleMouseMove: (e: React.MouseEvent) => void;
  handleMouseLeave: VoidFunction;
  showSwipeIcon: boolean;
  hideSwipeIcon: VoidFunction;
}
const useDragToScroll = (ref: RefObject<HTMLDivElement>): dragToScrollData => {
  const [isDown, setIsDown] = useState(false);
  const [isScrollActive, setIsScrollActive] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showSwipeIcon, setShowSwipeIcon] = useState(false);

  useEffect(() => {
    if (ref?.current?.scrollWidth === ref?.current?.clientWidth) {
      setShowSwipeIcon(false);
    } else {
      setShowSwipeIcon(true);
    }
  }, [ref?.current?.scrollWidth, ref?.current?.clientWidth]);

  const hideSwipeIcon = useCallback(() => {
    setShowSwipeIcon(false);
  }, []);

  const handleMouseDown = (e: React.MouseEvent): void => {
    const targetNodeName = (e.target as HTMLDivElement).nodeName;
    const excludedTargets = ['A'];
    if (!excludedTargets.includes(targetNodeName)) {
      if (ref.current != null) {
        setIsDown(true);
        setIsScrollActive(true);
        setStartX(e.pageX - ref.current.offsetLeft);
        setScrollLeft(ref.current.scrollLeft);
      }
    }
  };

  const handleMouseUp = (): void => {
    if (ref.current != null) {
      setIsDown(false);
      setIsScrollActive(false);
    }
  };

  const handleMouseMove = (e: React.MouseEvent): void => {
    if (ref.current != null) {
      if (!isDown) {
        return;
      }
      e.preventDefault();
      const x = e.pageX - ref.current.offsetLeft;
      const walk = (x - startX) * 2;
      ref.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleMouseLeave = (): void => {
    if (ref.current != null) {
      setIsDown(false);
      setIsScrollActive(false);
    }
  };

  return {
    ref,
    isScrollActive,
    handleMouseDown,
    handleMouseUp,
    handleMouseMove,
    handleMouseLeave,
    showSwipeIcon,
    hideSwipeIcon,
  };
};

export default useDragToScroll;
