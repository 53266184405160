import useDebounce from 'helpers/hooks/useDebounce';
import { Locales, getSwiftTypeUrlForSearchFilter, getSwiftTypeUrlForSearchList } from 'helpers/utils/swiftType';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

interface UseSwifTypeReturn {
  searchTerm: string;
  searchResults: ISearchResults[];
  searchInfo: ISearchInFo;
  error: any;
  handleSearchTermChange: (newTerm: string) => void;
  isLoading: boolean;
}

interface ISearchResults {
  id: string;
  title: string;
  description: string;
  image: string;
  url: string;
  niProductRatingVL: string;
  niProductRatingCt: string;
  niSiteType: string;
  published_at: string;
  type: string;
  niReviewable_at: string;
}

interface IFacets {
  Products?: number;
  LE?: number;
  Accessories?: number;
  Press?: number;
  Parts?: number;
  Support?: number;
  PDF?: number;
  Other?: number;
}
interface ISearchInFo {
  query: string;
  current_page: number;
  num_pages: number;
  per_page: number;
  total_result_count: number;
  facets: IFacets;
}

const fields = [
  'url',
  'title',
  'image',
  'description',
  'id',
  'niProductRatingVL',
  'niProductRatingCt',
  'niSiteType',
  'published_at',
  'type',
  'niReviewable_at',
];

const functionalBoosts = { niBoostType: 'linear', niBoostProductIsNew: 'linear', niBoostProductIsOnSale: 'linear' };

const mapFacets = (searchInfo: IFacets) => {
  const newInfo: any = {};
  if (searchInfo) {
    Object.keys(searchInfo).map((item) => {
      if (!item) {
        //@ts-ignore
        return (newInfo['Other'] = searchInfo[item]);
      }
      //@ts-ignore
      return (newInfo[item] = searchInfo[item]);
    });
  }

  return newInfo;
};

const useSwifTypeFacets = (locale: Locales): UseSwifTypeReturn => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, isDebounceLoading] = useDebounce(searchTerm, 150, '');
  const swiftTypeEndpoint = getSwiftTypeUrlForSearchFilter(
    { query: debouncedSearchTerm, fields, functionalBoosts },
    locale,
  );

  const { data, error, isValidating } = useSWR(
    searchTerm && swiftTypeEndpoint ? swiftTypeEndpoint : null,
    (url) => fetch(url).then((res) => res.json()),
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: true },
  );

  const handleSearchTermChange = (newTerm: string) => {
    setSearchTerm(newTerm);
  };

  const isLoading = isValidating || isDebounceLoading || (!data && !error);

  return {
    searchTerm,
    searchResults: data?.records?.page && !isLoading ? data?.records.page : [],
    searchInfo:
      !isLoading && data?.info
        ? {
            ...data?.info?.page,
            facets: {
              ...mapFacets(data?.info?.page.facets.type),
            },
          }
        : {},
    handleSearchTermChange,
    isLoading,
    error,
  };
};

export default useSwifTypeFacets;
