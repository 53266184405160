import React from 'react';
import { useRouter } from 'next/router';
import { Money } from '@commercetools/frontend-domain-types/product';
import { Row } from 'react-bootstrap';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { getProductDiscountsByPosition } from 'helpers/productHelpers';
import { TNikonVariant } from 'types/TNikonProduct';
import styles from './FeaturedProductForSearch.module.scss';

interface Props {
  variant: TNikonVariant;
}

const FeaturedProductPrice = ({ variant }: Props) => {
  const router = useRouter();
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const variantPrice = variant?.price?.custom?.fields.basePrice || variant?.price;
  const variantDiscount = variant?.price?.custom?.fields.basePrice ? variant?.price : variant?.discountedPrice;
  const discountAmount = variantDiscount && CurrencyHelpers.subtractCurrency(variantPrice, variantDiscount);
  const discountsMessagesByPosition = variant?.discounts && getProductDiscountsByPosition(variant.discounts[0]);

  const discountMessage =
    (discountAmount?.centAmount || 0) > 0 ? (
      <>
        {formatProductMessage({
          id: 'product.youSave',
          defaultMessage: 'You Save',
        })}
        &nbsp;
        {CurrencyHelpers.formatForCurrency(discountAmount as Money, router.locale)}
      </>
    ) : null;

  const customMessages = [variant?.price.custom?.fields.marketingCopy, discountsMessagesByPosition?.brow].filter(
    Boolean,
  );
  const instanSavingMessages = customMessages.length ? customMessages : [discountMessage].filter(Boolean);

  const formattedPrice = CurrencyHelpers.formatForCurrency(variantPrice, router.locale);
  const formattedDiscount = discountAmount?.centAmount
    ? CurrencyHelpers.formatForCurrency(variantDiscount as Money, router.locale)
    : '';

  return (
    <Row className={styles['price-container']}>
      <div className={styles['price']}>
        {!Boolean(discountAmount?.centAmount) && (
          <span className={styles['price-text']}>
            {formatProductMessage({
              id: 'product.featured.price',
              defaultMessage: 'Price {price}',
              values: { price: formattedDiscount },
            })}
          </span>
        )}

        {Boolean(discountAmount?.centAmount) && (
          <>
            <span className={styles['price-text']}>
              {formatProductMessage({
                id: 'product.featured.price.now',
                defaultMessage: 'Now {price}',
                values: { price: formattedDiscount },
              })}
            </span>
            <span className={styles['price-was']}>
              {formatProductMessage({
                id: 'product.featured.price.reg',
                defaultMessage: 'Reg. {price}',
                values: { price: formattedPrice },
              })}
            </span>
          </>
        )}
      </div>

      <span className={styles['you-save']} data-testid="instant-savings">
        {instanSavingMessages.map((message, index) => (
          <div key={`${variant?.sku}-${index}`}>{message}</div>
        ))}
      </span>
    </Row>
  );
};

export default FeaturedProductPrice;
