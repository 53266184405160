import React from 'react';
import { Product } from '@commercetools/frontend-domain-types/product/Product';
import { Container } from 'react-bootstrap';
import ContentBlocks from 'components/contentstack/ContentBlocks';
import Wrapper from 'components/HOC/wrapper';
import { TCategoryPage } from 'types/TCategoryPage';
import ArchivedSection from './components/archived-section/ArchivedSection';
import FilterFooter from './components/filters/footer/filter-footer';
import FilterHeader from './components/filters/header/filter-header';
import ListProductsWithFilters from './components/list-products-with-filters/list-products-with-filters';
import styles from './styles/index.module.scss';
import { Category } from '../../../../../types/category';
import { TNikonProduct } from '../../../../../types/TNikonProduct';
import { CollapseProvider } from '../../../atoms/collapse/Collapse';

export interface Props {
  products: Product[];
  archiveProducts: Product[];
  category?: Category;
  content?: TCategoryPage;
  showArchiveSection?: boolean;
}

const ProductList: React.FC<Props> = ({ products, archiveProducts, category, content, showArchiveSection }) => {
  return (
    <div className="min-h-screen pb-5">
      <Wrapper className="relative" clearDefaultStyles={true}>
        {content && (
          <div className={styles['plp-above-grid']}>
            <ContentBlocks data={content.contentBlocks} />
          </div>
        )}
        <Container id="plp-grid-container" className={styles['plp-grid-container']}>
          <FilterHeader />
          <ListProductsWithFilters products={products} />
          {Boolean(products?.length) && <FilterFooter />}
        </Container>
        {showArchiveSection && (
          <CollapseProvider>
            <ArchivedSection products={archiveProducts as TNikonProduct[]} category={category} />
          </CollapseProvider>
        )}
      </Wrapper>
    </div>
  );
};

export default ProductList;
