import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { appLocales } from 'helpers/constants/appLocales';
import { PAGE_VIEW_EVENTS, USER_CATEGORY } from 'helpers/constants/seoConstants';
import { getLocalizationInfo } from 'project.config';
import { TLocalizationInfo } from 'types/TLocalizationInfo';
import { useAccount } from 'frontastic';
import DidYouMean from './components/search-did-you-mean';
import SearchList from './components/search-list';
import SearchNav from './components/search-nav';

const Search = () => {
  const router = useRouter();
  const locale: TLocalizationInfo = getLocalizationInfo(router.locale);
  const { account, accountLoading } = useAccount();

  useEffect(() => {
    if (typeof window !== 'undefined' && !accountLoading && locale.appLocale !== appLocales.PT_BR) {
      sendGTMEvent({
        event: 'page_view',
        pageName: PAGE_VIEW_EVENTS.SEARCH.NAME,
        pageCategory: PAGE_VIEW_EVENTS.SEARCH.CATEGORY,
        pageType: PAGE_VIEW_EVENTS.SEARCH.TYPE,
        userId: account?.accountId,
        userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      });
    }
  }, [accountLoading]);

  if (locale.appLocale === appLocales.PT_BR) return null;

  return (
    <>
      <SearchNav />
      <DidYouMean />
      <SearchList />
    </>
  );
};

export default Search;
