import { useRef } from 'react';
import { Product } from '@commercetools/frontend-domain-types/product/Product';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import styles from './ListProductsWithFilters.module.scss';
import { useProductList } from '../../context';
import NarrowBy from '../filters/facets/narrow-by/narrow-by';
import YouSelected from '../filters/facets/you-selected/you-selected';
import List from '../list';
import TableDefinitions, { LinkToTableDefinitions } from '../table-definitions';

interface Props {
  products: Product[];
}

const ListProductsWithFilters = ({ products }: Props) => {
  const { view, isLensesSection } = useProductList();
  const { formatMessage } = useFormat({ name: 'plp' });
  const [isBiggerThanTablet] = useMediaQuery(tablet);
  const isTableView = view === 'table';
  const tableDefinitionsRef = useRef<HTMLDivElement>(null);
  const listContainerRef = useRef<HTMLDivElement>(null);

  const handleScrollToTableDefinitions = () => {
    tableDefinitionsRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleScrollToTableProductList = () => {
    listContainerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const showLinkToTableDefinitions = isTableView && isLensesSection && isBiggerThanTablet;

  return (
    <>
      {showLinkToTableDefinitions && <LinkToTableDefinitions goToTable={handleScrollToTableDefinitions} />}
      <div className={styles['container']}>
        <div className={styles['filters']}>
          <YouSelected />
          <NarrowBy />
        </div>
        <div className="overflow-auto" ref={listContainerRef}>
          {products?.length ? (
            <List products={products} />
          ) : (
            <p className={styles['no-results-found-text']}>
              {formatMessage({ id: 'noResultsFound', defaultMessage: 'No Results Found' })}
            </p>
          )}
          {isTableView && isLensesSection && (
            <TableDefinitions ref={tableDefinitionsRef} onBackToTop={handleScrollToTableProductList} />
          )}
        </div>
      </div>
    </>
  );
};

export default ListProductsWithFilters;
