import { useFormat } from 'helpers/hooks/useFormat';
import styles from './SearchList.module.scss';

const SearchListLoading = () => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });

  return (
    <div className={styles['search-results-loader-container']}>
      <img
        src="/images/icon_loading.gif"
        alt={formatCommonMessage({ id: 'search.loading', defaultMessage: 'loading' })}
      />
    </div>
  );
};

export default SearchListLoading;
