import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styles from './SelectDropdown.module.scss';

interface Props {
  title: string;
  options: { label: string; value: string }[];
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const SelectDropdown = ({ title, options, defaultValue, onChange }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue || options[0]?.value);

  useEffect(() => {
    setSelectedValue(defaultValue || options[0]?.value);
  }, [defaultValue, options]);

  const handleOnClick = (value: string) => {
    setSelectedValue(value);
    onChange?.(value);
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLElement>, value: string) => {
    if (event.key === 'Enter') {
      handleOnClick(value);
    }
  };

  return (
    <Dropdown className={styles.dropdown}>
      <Dropdown.Toggle variant="outline-dark">
        <strong>{title}</strong> {options.find((o) => o.value === selectedValue)?.label}
      </Dropdown.Toggle>
      <Dropdown.Menu as="ul">
        {options.map((item, index) => {
          return (
            <Dropdown.Item
              as="li"
              tabIndex={0}
              key={index}
              active={selectedValue === item.value}
              onKeyDown={(e) => handlePressEnter(e, item.value)}
              onClick={() => handleOnClick(item.value)}
            >
              {item.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
