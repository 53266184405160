import { XMarkIcon as CloseIcon } from '@heroicons/react/24/solid';
import styles from './YouSelected.module.scss';
import { useFormat } from '../../../../../../../../../helpers/hooks/useFormat';
import { ActiveRefinement } from '../../../../context/types';

interface Props {
  refinement: ActiveRefinement;
}

const CurrentRefinementItem = ({ refinement }: Props) => {
  const { formatMessage } = useFormat({ name: 'narrow' });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      refinement.refine();
    }
  };

  return (
    <li
      className={styles['current-refinement-item']}
      onClick={refinement.refine}
      onKeyDown={handleKeyDown}
      role="button"
      tabIndex={0}
    >
      <span className={styles['current-refinement-item-text']}>
        {formatMessage({ id: refinement.key, defaultMessage: refinement.label.replace('Product Type', '') })}
      </span>
      <CloseIcon className={styles['current-refinement-item-icon']} strokeWidth={4} stroke="black" />
    </li>
  );
};

export default CurrentRefinementItem;
