import { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import Button from 'components/commercetools-ui/atoms/button';
import { useProductList } from 'components/commercetools-ui/organisms/product/product-list/context';
import { useFormat } from 'helpers/hooks/useFormat';
import stylesMobile from './NarrowByMobile.module.scss';
import { FilterField } from '../../../../../../../../../../../types/product/FilterField';
import FilterItem from '../filter-item/filter-item';
import styles from '../NarrowBy.module.scss';

const NarrowByMobile = () => {
  const { formatMessage } = useFormat({ name: 'product' });
  const [show, setShow] = useState(false);
  const { facetsConfiguration, totalItems, removeAllRefinements } = useProductList();

  const handleClose = () => setShow(false);

  const resultsText =
    totalItems === 1
      ? formatMessage({ id: 'plp.filters.result', defaultMessage: '1 Result' })
      : formatMessage({
          id: 'plp.filters.results',
          defaultMessage: `${totalItems} Results`,
          values: { count: totalItems },
        });

  return (
    <>
      <div className={stylesMobile['filter-container']}>
        <Button className="btn-gray" onClick={() => setShow(true)}>
          {formatMessage({ id: 'plp.filters.filters', defaultMessage: 'Filters' })}
        </Button>
      </div>
      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header className={styles['header']}>
          <Offcanvas.Title>
            <div>{formatMessage({ id: 'plp.filters.narrowBy', defaultMessage: 'Narrow By' })}</div>
            <div className={styles['results']}>{resultsText}</div>
          </Offcanvas.Title>
          <Button onClick={handleClose} variant="secondary" size="xs" className={styles['content-button']}>
            {formatMessage({ id: 'plp.filters.viewProducts', defaultMessage: 'View Products' })}
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body className={styles['content']}>
          <ul className={styles['content-list']}>
            {facetsConfiguration?.map((filter: FilterField) => {
              if (!filter.values?.length) return null;

              return <FilterItem key={filter.field} facet={filter} />;
            })}
          </ul>
        </Offcanvas.Body>
        <div className={styles['footer']}>
          <Button onClick={removeAllRefinements} variant="secondary" size="xs" className={styles['content-button']}>
            {formatMessage({ id: 'plp.filters.clearAll', defaultMessage: 'Clear All' })}
          </Button>
          <Button onClick={handleClose} variant="secondary" size="xs" className={styles['content-button']}>
            {formatMessage({ id: 'plp.filters.viewProducts', defaultMessage: 'View Products' })}
          </Button>
        </div>
      </Offcanvas>
    </>
  );
};

export default NarrowByMobile;
