import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { Col, Row } from 'react-bootstrap';
import { IMAGE_SOURCE } from 'helpers/constants/imageSources';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './PressRoomForSearch.module.scss';
import { ISearchResults } from '../../types';

interface IProps {
  searchItem: ISearchResults;
}
const PressRoomForSearch = ({ searchItem }: IProps) => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const router = useRouter();
  const imageUnavailable = IMAGE_SOURCE.getUnavailableImage(router.locale);

  return (
    <Row className={styles['search-result-hero']}>
      <Col md={3} className={styles[`image`]}>
        <img
          src={searchItem?.image || imageUnavailable}
          onError={({ currentTarget }) => {
            currentTarget.src = imageUnavailable;
          }}
          alt={searchItem?.title}
        />
      </Col>
      <Col md={9} className={styles[`content`]}>
        <Row>
          <Col md={9} className={styles[`main-text`]}>
            <Row>
              <Col md={12}>
                <a className={styles[`url`]} href={`${searchItem?.url}`}>
                  <div dangerouslySetInnerHTML={{ __html: searchItem?.highlight?.title || searchItem.title }} />
                  {Boolean(searchItem?.url) &&
                    Boolean(searchItem.url.includes('-refurbished')) &&
                    formatCommonMessage({ id: 'search.refurbished', defaultMessage: `Refurbished` })}
                </a>
              </Col>
              <Col md={12} className={styles[`product-description`]}>
                <p
                  className={styles[`description`]}
                  dangerouslySetInnerHTML={{
                    __html: searchItem?.highlight?.description || searchItem.description,
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={3} className={styles[`extra-content`]}>
            {Boolean(searchItem?.published_at) && (
              <span className={styles[`date`]}>{dayjs(searchItem?.published_at).format('LL')}</span>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PressRoomForSearch;
