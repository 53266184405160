import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import useClassNames from 'helpers/hooks/useClassNames';

export interface CheckboxProps extends Omit<React.ComponentProps<'input'>, 'onChange'> {
  label?: string;
  labelPosition?: 'on-left' | 'on-right';
  containerClassName?: string;
  onChange?: (props: { name: string; checked: boolean }) => void;
  disableBackground?: boolean;
  refreshOnStateChange?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  checked,
  disabled,
  onChange,
  containerClassName,
  label,
  defaultChecked = false,
  labelPosition = 'on-right',
  disableBackground = false,
  refreshOnStateChange = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked ?? defaultChecked ?? false);

  useEffect(() => {
    if (refreshOnStateChange) {
      setIsChecked(checked ?? false);
    }
  }, [checked]);

  const toggleIsChecked = useCallback(() => {
    onChange?.({ name: props.name ?? '', checked: !isChecked });
    setIsChecked(!isChecked);
  }, [isChecked, onChange, props.name]);

  const containerClassNames = useClassNames(['mb-3', containerClassName]);

  return (
    <Form.Group className={containerClassNames}>
      <Form.Check
        //@ts-ignore
        type="checkbox"
        className={`customFormCheckbox ${className}`}
        label={label}
        checked={isChecked}
        onChange={toggleIsChecked}
        disabled={disabled}
        {...props}
        reverse={labelPosition === 'on-left'}
      />
    </Form.Group>
  );
};

export default Checkbox;
