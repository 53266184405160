import { useFormat } from 'helpers/hooks/useFormat';
import CurrentRefinementItem from './current-refinement-item';
import styles from './YouSelected.module.scss';
import { useProductList } from '../../../../context';

const YouSelected = () => {
  const { activeRefinements, removeAllRefinements } = useProductList();
  const { formatMessage } = useFormat({ name: 'product' });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      removeAllRefinements();
    }
  };

  return (
    <div className={styles['container']}>
      <p className={styles['header']}>
        {formatMessage({ id: 'plp.filters.selected.title', defaultMessage: "Filters You've Selected" })}
      </p>
      <div className={styles['content']}>
        {activeRefinements?.length ? (
          <>
            <ul className={styles['current-refinements-list']}>
              {activeRefinements.map((refinement) => (
                <CurrentRefinementItem key={`${refinement.attribute}.${refinement.label}`} refinement={refinement} />
              ))}
            </ul>
            <span
              className={styles['clear-all-refinements-text']}
              onClick={removeAllRefinements}
              onKeyDown={handleKeyDown}
              role="button"
              tabIndex={0}
            >
              {formatMessage({ id: 'plp.filters.clearAll', defaultMessage: 'Clear All' })}
            </span>
          </>
        ) : (
          <span>
            {formatMessage({
              id: 'plp.filters.selected.empty',
              defaultMessage: 'Use the filters to narrow your results.',
            })}
          </span>
        )}
      </div>
    </div>
  );
};

export default YouSelected;
