import { FC } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { tabsEventKey } from 'components/contentstack/ProductOverviewPage/Brow/types/ETabKeys';
import RatingStars from 'components/nikon-ui/atoms/RatingStars';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocale } from 'helpers/hooks/useLocale';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { TNikonVariant } from 'types/TNikonProduct';
import { ProductListItemProps } from '..';
import {
  getProductDiscountsByPosition,
  getVariantLowerPrice,
  hasMultiplePromoMessages,
} from '../../../../../../helpers/productHelpers';
import { getPromoMessage } from '../../product-tile/utils';
import styles from '../ProductListItem.module.scss';

interface ProductFooterProps extends Pick<ProductListItemProps, 'product'> {
  selectedVariant: TNikonVariant;
  productUrl: string;
  promotions: { amount: number; save: number };
  onClickLearnMore: () => void;
}

const ProductFooter: FC<ProductFooterProps> = ({
  product,
  selectedVariant,
  productUrl,
  promotions,
  onClickLearnMore,
}) => {
  const router = useRouter();
  const [isNotMobile] = useMediaQuery(tablet);
  const { formatMessage } = useFormat({ name: 'product' });
  const { isUsaStore, isCanadaStore, locale } = useLocale();
  const isShowPrice = isUsaStore || isCanadaStore;
  const isShowRating = isUsaStore && !product?.isArchived && product?.variants[0]?.attributes.isReviewable;

  const variantLowerPrice = getVariantLowerPrice(product.variants);

  const haveMultipleVariants = product.variants.length > 1;
  const variantDiscountedPrice = variantLowerPrice.discountedPrice;
  const customBasePrice = variantLowerPrice.price.custom?.fields?.basePrice;
  const price = customBasePrice || variantLowerPrice?.price;
  const discountedPrice = customBasePrice
    ? variantDiscountedPrice // This is when the product have both discounts configured, a discount price and a marketing copy discount
      ? variantDiscountedPrice
      : variantLowerPrice?.price
    : variantDiscountedPrice || undefined;
  const startingAtPrice = discountedPrice || price;

  const marketingCopy = variantLowerPrice.price.custom?.fields?.marketingCopy;
  const marketingCopyDiscount = selectedVariant?.price?.custom?.fields?.basePrice;

  const browMessage =
    variantLowerPrice.discounts && getProductDiscountsByPosition(variantLowerPrice.discounts[0])?.brow;

  const isShowDiscount = Boolean(discountedPrice && (isUsaStore || isCanadaStore) && !haveMultipleVariants);

  const isShowMultiplePromoMessage = Boolean(
    (isUsaStore || isCanadaStore) && hasMultiplePromoMessages(product.variants),
  );
  const isShowMarketingCopy = isShowDiscount && !isShowMultiplePromoMessage && marketingCopy;
  const isShowBrowMessage = isShowDiscount && !isShowMultiplePromoMessage && browMessage;
  const isShowYouSaveMessage =
    isShowDiscount &&
    !isShowMultiplePromoMessage &&
    marketingCopyDiscount &&
    !isShowMarketingCopy &&
    !isShowBrowMessage;

  return (
    <div>
      <div className="mb-20">
        <div className="mt-10">
          {haveMultipleVariants && (
            <p className="text-xs">
              {formatMessage({
                id: 'product.options',
                defaultMessage: '{optionsAmount} Options Available',
                values: { optionsAmount: product.variants.length },
              })}
            </p>
          )}

          {isShowPrice && !isShowDiscount && price?.centAmount && (
            <p className={styles.currentPrice}>
              {haveMultipleVariants &&
                formatMessage({
                  id: 'product.startingAt',
                  defaultMessage: 'Starting at {price}',
                  values: { price: CurrencyHelpers.formatForCurrency(startingAtPrice, router.locale) },
                })}

              {!haveMultipleVariants && CurrencyHelpers.formatForCurrency(selectedVariant.price, router.locale)}
            </p>
          )}

          {!haveMultipleVariants && isShowPrice && isShowDiscount && discountedPrice && (
            <>
              {isUsaStore && (
                <>
                  <span className={styles['price-was']}>{CurrencyHelpers.formatForCurrency(price, router.locale)}</span>
                  <div className={styles.currentPrice}>
                    {CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}
                  </div>
                </>
              )}
              {isCanadaStore && (
                <>
                  <div className="text-sm font-bold">
                    {formatMessage({ id: 'sale.now', defaultMessage: 'Now' })}{' '}
                    {CurrencyHelpers.formatForCurrency(discountedPrice, router.locale)}
                  </div>
                  <span className="text-xs">
                    {formatMessage({ id: 'sale.was', defaultMessage: 'Reg.' })}{' '}
                    {CurrencyHelpers.formatForCurrency(price, router.locale)}
                  </span>
                </>
              )}

              <div className={styles['you-save']}>
                {isShowYouSaveMessage && (
                  <>
                    {formatMessage({
                      id: 'product.youSave',
                      defaultMessage: 'You Save',
                    })}
                    &nbsp;
                    {CurrencyHelpers.formatForCurrency(
                      CurrencyHelpers.subtractCurrency(price, discountedPrice),
                      router.locale,
                    )}
                  </>
                )}
                {isShowMultiplePromoMessage &&
                  formatMessage({
                    id: 'product.multiplePromotions',
                    defaultMessage: 'Multiple Promotions Available',
                  })}

                {isShowMarketingCopy && marketingCopy}
                {isShowBrowMessage && browMessage}
              </div>
            </>
          )}
        </div>
        {isUsaStore && <div className={styles.promotion}>{getPromoMessage(product.variants)}</div>}
      </div>

      {isShowRating && (
        <div className="mb-10">
          <a className="no-underline" href={`${product._url}/overview#${tabsEventKey.RATING_AND_REVIEW}`}>
            <RatingStars product={product} variant={product.variants[0]} locale={locale} />
          </a>
        </div>
      )}

      {isNotMobile && (
        <NextLink href={productUrl}>
          <a className={`${styles.button} btn-yellow nk-btn-smooth-rounded`} onClick={onClickLearnMore}>
            {formatMessage({ id: 'product.learnMore', defaultMessage: 'Learn More' })}
          </a>
        </NextLink>
      )}
    </div>
  );
};

export default ProductFooter;
