import { forwardRef } from 'react';
import classNames from 'classnames';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './TableDefinitions.module.scss';
import { useProductList } from '../../context';
interface Props {
  onBackToTop: () => void;
}

export const LinkToTableDefinitions = ({ goToTable }: { goToTable: () => void }) => {
  const { formatMessage } = useFormat({ name: 'product' });

  return (
    <div className="mb-24 flex justify-end gap-2 text-xs">
      <div onClick={goToTable} className="group flex cursor-pointer justify-end gap-2 text-xs">
        <img alt="view definitions icon" className="w-15" src="/icons/openBook.svg" />
        <span className="group-hover:underline">
          {formatMessage({ id: 'product.definitions.viewTable', defaultMessage: 'View Table Definitions' })}
        </span>
      </div>
    </div>
  );
};

const TableDefinitions = forwardRef<HTMLDivElement, Props>(({ onBackToTop }, ref) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { tableDefinitions } = useProductList();

  return (
    <div ref={ref} className={styles['table-definitions']}>
      <header
        className={classNames(styles['table-definitions-title-container'], 'mt-48 flex items-center justify-between')}
      >
        <h2 className={styles['table-definitions-title']}>
          {formatMessage({ id: 'product.definitions.title', defaultMessage: 'Table Definitions' })}
        </h2>
        <div className="group flex cursor-pointer gap-2 text-xs" onClick={onBackToTop}>
          <img src="/icons/chevron-down.svg" className="w-15 rotate-180" />
          <span className="group-hover:underline">
            {formatMessage({ id: 'product.definitions.backToTop', defaultMessage: 'Back to top of table' })}
          </span>
        </div>
      </header>
      <ol className={styles['table-definitions-list']}>
        {tableDefinitions?.map(({ key, sourceHtml }) => (
          <li
            key={key}
            className={styles['table-definitions-list-item']}
            dangerouslySetInnerHTML={{ __html: sourceHtml }}
          />
        ))}
      </ol>
    </div>
  );
});

TableDefinitions.displayName = 'TableDefinitions';

export default TableDefinitions;
