import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ContentBlocksDTO } from 'DTO/ContentBlocksDTO';
import { Locales } from 'helpers/utils/swiftType';
import { TFreeEntry } from 'types/TFreeEntry';
import useSwifType from 'frontastic/hooks/useSwifType';
import { FeaturedCategory } from '../../../../../../../../../types/search/FeaturedCategory';
import { FeaturedProduct } from '../../../../../../../../../types/search/FeaturedProduct';
import { ISearchInFo, ISearchResults } from '../types';

interface SearchContextShape {
  searchTerm: string;
  searchResults: ISearchResults[];
  searchInfo?: ISearchInFo;
  page: number;
  isLoading?: any;
  isFacetLoading: boolean;
  changePage: (value: number) => void;
  setIsFacetLoading: (value: boolean) => void;
  changeSearchTerm: (value: string) => void;
  handleSearchFilter: (filters: string[]) => void;
  featuredSearchItems?: {
    featuredProduct?: FeaturedProduct;
    featuredCategory?: { contentBlocks: TFreeEntry[] };
  };
  query: string;
}

export const SearchContext = createContext<SearchContextShape>({
  searchTerm: '',
  searchResults: [],
  page: 1,
  changePage: () => {},
  changeSearchTerm: () => {},
  handleSearchFilter: () => {},
  featuredSearchItems: {},
  query: '',
  isFacetLoading: false,
  setIsFacetLoading: () => {},
});

interface Props {
  featuredProduct?: FeaturedProduct;
  featuredCategory?: FeaturedCategory;
  searchTerm?: string;
}

const SearchProvider: React.FC<Props> = ({
  children,
  featuredProduct,
  featuredCategory,
  searchTerm: queryValue = '',
}) => {
  const router = useRouter();
  const [page, setPage] = useState<number>(1);
  const [searchTerm, setSearchTerm] = useState<string>(queryValue);
  const [isFacetLoading, setIsFacetLoading] = useState(false);
  const { searchResults, handleSearchTermChange, handleSearchPageChange, handleSearchFilter, isLoading, searchInfo } =
    useSwifType(router?.locale?.replace('-', '_') as Locales, queryValue);

  useEffect(() => {
    if (searchTerm) handleSearchTermChange(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (page) handleSearchPageChange(page);
  }, [page]);

  useEffect(() => {
    if (queryValue) changeSearchTerm(queryValue);
  }, [queryValue]);

  const changePage = (value: number) => {
    if (value) setPage(value);
  };

  const changeSearchTerm = (value: string) => {
    if (value) setSearchTerm(value);
  };

  const changeSearchFilter = (value: string[]) => {
    handleSearchFilter(value);
  };

  const handleSetIsFacetLoading = (value: boolean) => {
    setIsFacetLoading(value);
  };

  const featuredCategoryFreeEntry = featuredCategory?.freeEntry;
  const featuredCategoryContentBlocks = featuredCategoryFreeEntry
    ? (
        ContentBlocksDTO([
          { free_entry_block: { free_entry_reference: featuredCategoryFreeEntry } },
        ]) as unknown as TFreeEntry[]
      )?.filter(({ isExpired }) => !isExpired)
    : null;

  const mapDataWithRefurbished = (data: ISearchResults[]) => {
    const refurbishedData: ISearchResults[] = [];
    const regularData: ISearchResults[] = [];
    data.forEach((item) => {
      if (Boolean(item?.url) && Boolean(item.url.includes('-refurbished'))) {
        refurbishedData.push(item);
      } else {
        regularData.push(item);
      }
    });
    return [...refurbishedData, ...regularData];
  };

  const featuredSearchItems = {
    featuredProduct,
    featuredCategory: featuredCategoryContentBlocks?.length
      ? { contentBlocks: featuredCategoryContentBlocks }
      : undefined,
  };

  const value = useMemo(
    () => ({
      searchResults: Boolean(searchResults.length) ? mapDataWithRefurbished(searchResults) : [],
      searchInfo,
      page,
      searchTerm,
      isLoading,
      isFacetLoading,
      setIsFacetLoading: handleSetIsFacetLoading,
      changePage,
      changeSearchTerm,
      handleSearchFilter: changeSearchFilter,
      featuredSearchItems,
      query: queryValue || '',
    }),
    [searchResults, searchInfo, page, searchTerm, isLoading, featuredSearchItems],
  );

  return <SearchContext.Provider value={value}>{children}</SearchContext.Provider>;
};

export default SearchProvider;

export const useSearch = () => useContext(SearchContext);
