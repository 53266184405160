import { FC } from 'react';
import NextLink from 'next/link';
import classNames from 'classnames';
import { sendGTMEvent } from 'components/headless/GTMSnippet';
import { USER_CATEGORY } from 'helpers/constants/seoConstants';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { desktop, tablet } from 'helpers/utils/screensizes';
import { TNikonProduct } from 'types/TNikonProduct';
import { useAccount } from 'frontastic';
import Image from 'frontastic/lib/image';
import ProductFooter from './components/ProductFooter';
import styles from './ProductListItem.module.scss';
import { useLocale } from '../../../../../helpers/hooks/useLocale';
import useProductListItem from '../hooks/useProductListItem';
export interface ProductListItemProps {
  product: TNikonProduct;
  getProductGTMEventData: (product: TNikonProduct, index?: number) => void;
  index: number;
}

const Badge: FC = ({ children }) => {
  return <span className={styles.badge}>{children} </span>;
};

const ProductListItem: FC<ProductListItemProps> = ({ product, getProductGTMEventData, index }) => {
  const {
    imageUnavailable,
    selectedVariant,
    setVariantById,
    swatchImages,
    productUrl,
    isSale,
    isNew,
    productName,
    promotions,
  } = useProductListItem(product);
  const { formatMessage } = useFormat({ name: 'product' });
  const [isDesktopSize] = useMediaQuery(desktop);
  const [isNotMobile] = useMediaQuery(tablet);
  const { isUsaStore, isCanadaStore } = useLocale();
  const { account } = useAccount();

  const handleOnClick = () => {
    sendGTMEvent({
      event: 'select_item',
      hit_time_stamp: Date.now(),
      userId: account?.accountId,
      userCategory: account?.accountId ? USER_CATEGORY.LOGGED_IN : USER_CATEGORY.GUEST,
      ecommerce: {
        actionField: { list: 'Product list', view: 'list' },
        items: [getProductGTMEventData(product, index)],
      },
    });
  };

  return (
    <div className={`${styles.tileBody} ${styles.listItem}`}>
      <div className="relative">
        <NextLink href={productUrl}>
          <a onClick={handleOnClick}>
            <div className="relative w-full">
              <div className="relative p-8 md:p-16">
                <div className="relative block w-full" style={{ paddingBottom: '122%' }}>
                  <Image
                    src={selectedVariant?.representativeImage || selectedVariant.images[0] || imageUnavailable}
                    onError={({ currentTarget }) => {
                      currentTarget.src = imageUnavailable;
                    }}
                    suffix="medium"
                    loading="lazy"
                    alt={product.name}
                    objectFit="contain"
                    objectPosition="center"
                    className="w-full shrink-0 rounded-sm group-hover:opacity-75 md:p-16"
                  />
                </div>
              </div>
            </div>
            {isNotMobile && (
              <div className={styles['variant-swatches']}>
                {swatchImages.map((variant, index) => (
                  <div
                    key={index}
                    className={classNames({ [styles['selected']]: variant.id === selectedVariant.id })}
                    onClick={(e) => {
                      e.preventDefault();
                      setVariantById(variant.id);
                    }}
                  >
                    <img alt={`variant ${index + 1}`} src={variant.image} loading="lazy" />
                  </div>
                ))}
              </div>
            )}
          </a>
        </NextLink>
      </div>
      <div>
        <div>
          <h3 className=" t mb-1 flex max-w-[80%] cursor-pointer flex-wrap overflow-hidden text-ellipsis text-sm font-bold">
            {isSale && <Badge>{formatMessage({ id: 'sale', defaultMessage: 'SALE' })}</Badge>}
            {isNew && !isSale && <Badge>{formatMessage({ id: 'new', defaultMessage: 'NEW' })}</Badge>}
            <NextLink href={productUrl}>
              <a onClick={handleOnClick}>{productName}</a>
            </NextLink>
          </h3>

          {(isUsaStore || isCanadaStore) && (
            <div className="text-xs font-bold">
              {formatMessage({ id: 'product', defaultMessage: 'Product' })} {product.key}
            </div>
          )}

          {isNotMobile && <div className="mt-10 text-xs">{product.description}</div>}

          {!isDesktopSize && (
            <ProductFooter
              promotions={promotions}
              product={product}
              selectedVariant={selectedVariant}
              productUrl={productUrl}
              onClickLearnMore={handleOnClick}
            />
          )}
        </div>
      </div>
      {isDesktopSize && (
        <ProductFooter
          promotions={promotions}
          product={product}
          selectedVariant={selectedVariant}
          productUrl={productUrl}
          onClickLearnMore={handleOnClick}
        />
      )}
    </div>
  );
};

export default ProductListItem;
