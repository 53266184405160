import { useState } from 'react';
import { useRouter } from 'next/router';
import { useFormat } from 'helpers/hooks/useFormat';
import { getLocalizationInfo } from 'project.config';
import { TLocalizationInfo } from 'types/TLocalizationInfo';
import { FIELDS } from '../Header/constants';
import { E_FIELDS } from '../Header/interfaces';

export interface TableFields {
  direction: 'down' | 'up';
  setDirection: React.Dispatch<React.SetStateAction<'down' | 'up'>>;
  fieldsToRender: (
    | {
        value: string;
        key: E_FIELDS;
        hideOn: string[];
        attributesKeys: string[];
        availableLocales?: undefined;
      }
    | {
        value: string;
        key: E_FIELDS;
        hideOn?: undefined;
        attributesKeys: string[];
        availableLocales?: undefined;
      }
    | {
        value: string;
        key: E_FIELDS;
        availableLocales: string[];
        attributesKeys: string[];
        hideOn?: undefined;
      }
  )[];
  toggleDirection: () => 'up' | 'down';
  isAnyFieldHidden: boolean;
  totalFields: number;
}

const useTableFields = (): TableFields => {
  const [direction, setDirection] = useState<'down' | 'up'>('up');
  const router = useRouter();
  const locale: TLocalizationInfo = getLocalizationInfo(router.locale);

  const path = router.asPath.split('?')[0];
  const { formatMessage } = useFormat({ name: 'plp' });

  const isHidden = (hideOn: string[] = [], availableLocales?: string[]) =>
    hideOn.some((hidePath) => path.endsWith(hidePath)) ||
    (availableLocales ? !availableLocales.includes(locale.appLocale) : false);

  const fieldsToRender = FIELDS.filter((field) => !isHidden(field.hideOn, field.availableLocales)).map((field) => ({
    ...field,
    value: formatMessage({ id: field.key, defaultMessage: field.value }),
  }));

  const isAnyFieldHidden = fieldsToRender.length < FIELDS.length;

  const toggleDirection = () => {
    const getDir = (prev: 'down' | 'up') => {
      if (prev === 'down') return 'up';
      if (prev === 'up') return 'down';
      return 'down';
    };
    setDirection(getDir);
    return getDir(direction);
  };

  return {
    direction,
    setDirection,
    fieldsToRender,
    toggleDirection,
    isAnyFieldHidden,
    totalFields: fieldsToRender.length,
  };
};

export default useTableFields;
