import React from 'react';
import { Navbar } from 'react-bootstrap';
import styles from './SearchNav.module.scss';
import SearchBrowBar from '../SearchBrowBar';

const SearchNav = () => {
  return (
    <Navbar expand="lg" sticky="top" fixed="top" className={styles.searchNav} bg="white">
      <div className={styles.searchBrowBar}>
        <div>
          <SearchBrowBar />
        </div>
        <div className={styles.insetOverlay}></div>
      </div>
    </Navbar>
  );
};

export default SearchNav;
