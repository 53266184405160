import React from 'react';
import classNames from 'classnames';
import { appLocales } from 'helpers/constants/appLocales';
import { RATINGS_BLOCK_STATUS } from 'helpers/constants/RatingsAndReviewBlocksConstants';
import { TURN_TO_MINIMUM_REVIEWS_THRESHOLD } from 'helpers/constants/turnTo';
import { useFormat } from 'helpers/hooks/useFormat';
import { getReviewStatus } from 'helpers/utils/ratingsAndReviewUtils';
import styles from './RatingStars.module.scss';
import { IRatingStarsProp } from './types/IRatingStarProp';

const RatingStars = ({ ratingCount, reviewableAt, avgRating, locale }: IRatingStarsProp) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const userCanReview = reviewableAt ? new Date(reviewableAt || '') < new Date() : true;
  const isUsaStore = locale === appLocales.EN_US;
  const numberOfReviews = ratingCount || 0;
  let reviewStatus = getReviewStatus({
    numberOfReviews,
    userCanReview,
  });

  let averageRating = parseFloat(avgRating.toFixed(1) || '0');

  if (TURN_TO_MINIMUM_REVIEWS_THRESHOLD > numberOfReviews || reviewStatus === RATINGS_BLOCK_STATUS.COMING_SOON) {
    averageRating = 0;
  }

  if (!isUsaStore) {
    return false as unknown as JSX.Element;
  }
  reviewStatus = !Boolean(numberOfReviews) && !reviewableAt ? RATINGS_BLOCK_STATUS.COMING_SOON : reviewStatus;
  const roundedAverageRating = Math.round(averageRating * 2) / 2; // round to nearest 0.5 or integer
  const ratingClassName = styles[`rating-${roundedAverageRating.toString().replace('.', '-')}`];

  return (
    <div className={styles['rating-stars']} data-testid="rating-stars">
      {Boolean(reviewStatus === RATINGS_BLOCK_STATUS.REVIEW_COUNT) && (
        <div
          className={classNames(styles['stars'], ratingClassName)}
          style={{ '--rating': averageRating } as React.CSSProperties}
          aria-label={formatProductMessage({
            id: 'ratings.ariaLabel',
            defaultMessage: '{averageRating} Star Rating out of 5',
            values: { averageRating },
          })}
          title={`${averageRating} Star Rating`}
        />
      )}

      <span className={styles.count} data-testid={reviewStatus}>
        {Boolean(reviewStatus === RATINGS_BLOCK_STATUS.COMING_SOON) &&
          formatProductMessage({
            id: 'ratings.reviewComingSoon',
            defaultMessage: 'Reviews are coming soon',
          })}

        {Boolean(reviewStatus === RATINGS_BLOCK_STATUS.BE_THE_FIRST_TO_WRITE) &&
          formatProductMessage({
            id: 'ratings.beTheFirstReview',
            defaultMessage: 'Be the first to write a review',
          })}

        {Boolean(reviewStatus === RATINGS_BLOCK_STATUS.REVIEW_COUNT) &&
          formatProductMessage({
            id: 'ratings.numberOfReviews',
            defaultMessage: '{numberOfReviews} reviews',
            values: { numberOfReviews: numberOfReviews },
          })}
      </span>
    </div>
  );
};

export default RatingStars;
