import Link from 'next/link';
import { Container } from 'react-bootstrap';
import { useFormat } from 'helpers/hooks/useFormat';
import styles from './DidYouMean.module.scss';
import { useSearch } from '../../context';

const DidYouMean = () => {
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'search' });
  const { searchTerm, isLoading, searchInfo, searchResults, featuredSearchItems } = useSearch();

  const noResults =
    !Boolean(searchResults.length) &&
    !Boolean(searchInfo?.spelling_suggestion?.text) &&
    !featuredSearchItems?.featuredProduct &&
    !featuredSearchItems?.featuredCategory?.contentBlocks;

  return !isLoading ? (
    <Container className={styles.searchSuggestionsContainer}>
      {Boolean(searchInfo?.spelling_suggestion?.text) && (
        <div className={styles.mainContainer}>
          <h2>{formatCommonMessage({ id: 'did_you_mean.heading', defaultMessage: `Did you mean:` })}</h2>
          <Link href={`/s/${searchInfo?.spelling_suggestion?.text}?facet=Overview`}>
            <a>{searchInfo?.spelling_suggestion?.text}</a>
          </Link>
        </div>
      )}
      {noResults ? (
        <div className={styles.searchSuggestions}>
          {formatCommonMessage({ id: 'did_you_mean.your_search', defaultMessage: `Your Search` })}{' '}
          <span className={styles.searchTerm} data-content="search-term">
            {searchTerm}{' '}
          </span>
          {formatCommonMessage({ id: 'did_you_mean.did_not_match', defaultMessage: `did not match any documents` })}
          <br />
          <br />
          {formatCommonMessage({ id: 'did_you_mean.suggestions', defaultMessage: `Suggestions` })}
          <br />
          <ul>
            <li>
              {formatCommonMessage({
                id: 'did_you_mean.make_sure',
                defaultMessage: `Make sure all words are spelled correctly`,
              })}
            </li>
            <li>
              {formatCommonMessage({ id: 'did_you_mean.try_different', defaultMessage: `Try different keywords.` })}
            </li>
            <li>
              {formatCommonMessage({ id: 'did_you_mean.try_more', defaultMessage: `Try more general keywords.` })}
            </li>
          </ul>
        </div>
      ) : null}
    </Container>
  ) : null;
};

export default DidYouMean;
