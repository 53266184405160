import styles from './FilterFooter.module.scss';
import Pagination from '../../../../../../../nikon-ui/atoms/pagination';
import { useProductList } from '../../../context';

const FilterFooter = () => {
  const { totalItems, activeLimit, offset, goToTopResults } = useProductList();

  return (
    <div className={styles['container']}>
      <div className={styles['filters']}>
        <div className={styles['title']}>
          {
            <Pagination
              pagination={{ total: totalItems, limit: activeLimit, offset }}
              onLoading={goToTopResults}
              scroll={false}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default FilterFooter;
