import { Sort } from './context/types';
import { E_FIELDS } from '../product-table/Header/interfaces';

export const MAP_TABLE_COLUMNS_SORT: Record<string, { up: Sort | Sort[]; down: Sort | Sort[] }> = {
  [E_FIELDS.LENS_MOUNT]: {
    up: [
      { attribute: 'variants.attributes.mountType.label.{locale}', value: 'asc' },
      { attribute: 'variants.attributes.focalLengthMin', value: 'asc' },
    ],
    down: [
      { attribute: 'variants.attributes.mountType.label.{locale}', value: 'desc' },
      { attribute: 'variants.attributes.focalLengthMax', value: 'desc' },
    ],
  },
  [E_FIELDS.FOCAL_LENGTH]: {
    up: { attribute: 'variants.attributes.focalLengthMin', value: 'asc' },
    down: { attribute: 'variants.attributes.focalLengthMax', value: 'desc' },
  },
  [E_FIELDS.APERTURE]: {
    up: { attribute: 'variants.attributes.maximumAperture', value: 'asc' },
    down: { attribute: 'variants.attributes.maximumAperture', value: 'desc' },
  },
  [E_FIELDS.LENS_NAME]: {
    up: { attribute: 'name.{locale}', value: 'asc' },
    down: { attribute: 'name.{locale}', value: 'desc' },
  },
  [E_FIELDS.LENS_FORMAT]: {
    up: { attribute: 'variants.attributes.format', value: 'asc' },
    down: { attribute: 'variants.attributes.format', value: 'desc' },
  },
  [E_FIELDS.FOCUS_TYPE]: {
    up: { attribute: 'variants.attributes.focusMode', value: 'asc' },
    down: { attribute: 'variants.attributes.focusMode', value: 'desc' },
  },
  [E_FIELDS.PRICE]: {
    up: { attribute: 'variants.scopedPrice.currentValue.centAmount', value: 'asc' },
    down: { attribute: 'variants.scopedPrice.currentValue.centAmount', value: 'desc' },
  },
};

export enum E_SORT_OPTIONS_KEY {
  FEATURED = 'featured',
  NAME_AZ = 'nameAz',
  NAME_ZA = 'nameZa',
  PRICE_LOW_HIGH = 'priceLowHigh',
  PRICE_HIGH_LOW = 'priceHighLow',
  RATINGS = 'ratings',
  NEWEST = 'newest',
  LENS_MOUNT = 'lensMount',
  FOCAL_LENGTH_LOW_HIGH = 'focalLengthLowHigh',
  FOCAL_LENGTH_HIGH_LOW = 'focalLengthHighLow',
}

export const MAP_SORT_OPTIONS: Record<string, Sort | Sort[]> = {
  [E_SORT_OPTIONS_KEY.NAME_AZ]: { attribute: 'name.{locale}', value: 'asc' },
  [E_SORT_OPTIONS_KEY.NAME_ZA]: { attribute: 'name.{locale}', value: 'desc' },
  [E_SORT_OPTIONS_KEY.PRICE_LOW_HIGH]: { attribute: 'variants.scopedPrice.currentValue.centAmount', value: 'asc' },
  [E_SORT_OPTIONS_KEY.PRICE_HIGH_LOW]: { attribute: 'variants.scopedPrice.currentValue.centAmount', value: 'desc' },
  [E_SORT_OPTIONS_KEY.RATINGS]: [
    { attribute: 'variants.attributes.isReviewable', value: 'desc' },
    { attribute: 'reviewRatingStatistics.averageRating', value: 'desc' },
  ],
  [E_SORT_OPTIONS_KEY.NEWEST]: { attribute: 'variants.attributes.newUntil', value: 'desc' },
  [E_SORT_OPTIONS_KEY.LENS_MOUNT]: { attribute: 'variants.attributes.mountType.label.{locale}', value: 'desc' },
  [E_SORT_OPTIONS_KEY.FOCAL_LENGTH_LOW_HIGH]: {
    attribute: 'variants.attributes.focalLengthMin',
    value: 'asc',
  },
  [E_SORT_OPTIONS_KEY.FOCAL_LENGTH_HIGH_LOW]: {
    attribute: 'variants.attributes.focalLengthMax',
    value: 'desc',
  },
};

export const SortByDropdownItems = [
  { label: 'Featured', value: 'featured' },
  { label: 'Name (A-Z)', value: 'nameAz' },
  { label: 'Name (Z-A)', value: 'nameZa' },
  { label: 'Price low to high', value: 'priceLowHigh' },
  { label: 'Price high to low', value: 'priceHighLow' },
  { label: 'Ratings', value: 'ratings' },
  { label: 'Newest', value: 'newest' },
  { label: 'Lens mount', value: 'lensMount' },
  { label: 'Focal length low to high', value: 'focalLengthLowHigh' },
  { label: 'Focal length high to low', value: 'focalLengthHighLow' },
];
